export default [
  {
    path: '/history',
    children: [
      {
        path: '',
        name: 'TransactionHistory',
        meta: {
          title: 'Riwayat Transaksi',
          tag: 'sale history riwayat penjualan',
          permission: ['manage_sales', 'view_sale'],
          description: 'Halaman yang menampilkan daftar rwayat semua penjualan',
        },
        component: () => import(/* webpackChunkName: "transaction-history" */ '../views/Sale/Index.vue'),
      },
      {
        path: ':sale_id',
        name: 'TransactionHistoryDetail',
        meta: { title: 'Riwayat Transaksi - Tampilkan Riwayat' },
        component: () => import(/* webpackChunkName: "transaction-history" */ '../views/Sale/View.vue'),
      },
    ],
  },
  {
    path: '/sale',
    children: [
      {
        path: '',
        name: 'SaleIndex',
        meta: {
          title: 'Penjualan',
          tag: 'sale penjualan',
          permission: ['manage_sales', 'view_sale'],
          description: 'Halaman yang menampilkan daftar semua penjualan semua cabang',
        },
        component: () => import(/* webpackChunkName: "sale-index" */ '../views/Sale/Index.vue'),
      },
      {
        path: ':sale_id',
        name: 'SaleView',
        meta: { title: 'Penjualan - Tampilkan Penjualan' },
        component: () => import(/* webpackChunkName: "sale-view" */ '../views/Sale/View.vue'),
      },
    ],
  },
  {
    path: '/sale-payment',
    children: [
      {
        path: '',
        name: 'SalePaymentIndex',
        meta: {
          title: 'Pembayaran Penjualan',
          tag: 'sale payment pembayaran penjualan',
          permission: ['manage_sale_payments', 'view_sale_payment'],
          description: 'Halaman yang menampilkan daftar semua pembayaran penjualan',
        },
        component: () => import(/* webpackChunkName: "sale-payment-index" */ '../views/Sale/Payment/Index.vue'),
      },
      {
        path: ':sale_payment_id',
        name: 'SalePaymentView',
        meta: { title: 'Pembayaran Penjualan - Tampilkan Pembayaran' },
        component: () => import(/* webpackChunkName: "sale-payment-view" */ '../views/Sale/Payment/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: ':sale_payment_id/edit',
            name: 'SalePaymentEdit',
            meta: { title: 'Pembayaran Penjualan - Ubah Pembayaran' },
            component: () => import(/* webpackChunkName: "sale-payment-edit" */ '../views/Sale/Payment/Form.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/sale-retur',
    children: [
      {
        path: '',
        name: 'SaleReturIndex',
        meta: {
          title: 'Pengembalian Penjualan',
          tag: 'sale retur pengembalian penjualan',
          permission: ['manage_sale_returs', 'view_sale_retur'],
          description: 'Halaman yang menampilkan daftar pengembalian penjualan',
        },
        component: () => import(/* webpackChunkName: "sale-retur-index" */ '../views/Sale/Retur/Index.vue'),
      },
      {
        path: ':sale_retur_id',
        name: 'SaleReturView',
        meta: { title: 'Pengembalian Penjualan - Tampilkan Pengembalian' },
        component: () => import(/* webpackChunkName: "sale-retur-view" */ '../views/Sale/Retur/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'SaleReturCreate',
            meta: {
              title: 'Pengembalian Penjualan - Tambah Pengembalian',
              tag: 'create sale retur tambah pengembalian penjualan',
              permission: ['manage_sale_returs'],
              description: 'Halaman untuk menambah pengembalian penjualan',
            },
            component: () => import(/* webpackChunkName: "sale-retur-create" */ '../views/Sale/Retur/Form.vue'),
          },
          {
            path: ':sale_retur_id/edit',
            name: 'SaleReturEdit',
            meta: { title: 'Pengembalian Penjualan - Ubah Pengembalian' },
            component: () => import(/* webpackChunkName: "sale-retur-edit" */ '../views/Sale/Retur/Form.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/sale-shipping',
    children: [
      {
        path: '',
        name: 'SaleShippingIndex',
        meta: {
          title: 'Pengiriman Penjualan',
          tag: 'sale shipping pengiriman penjualan',
          permission: ['manage_sale_shippings', 'view_sale_shipping'],
          description: 'Halaman yang menampilkan daftar semua pengiriman penjualan',
        },
        component: () => import(/* webpackChunkName: "sale-shipping-index" */ '../views/Sale/Shipping/Index.vue'),
      },
      {
        path: ':sale_shipping_id',
        name: 'SaleShippingView',
        meta: { title: 'Pengiriman Penjualan - Tampilkan Pengiriman' },
        component: () => import(/* webpackChunkName: "sale-shipping-edit" */ '../views/Sale/Shipping/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'SaleShippingCreate',
            meta: {
              title: 'Pengiriman Penjualan - Tambah Pengiriman',
              tag: 'sale shipping pengiriman penjualan',
              permission: ['manage_sale_shippings'],
              description: 'Halaman untuk menambah pengiriman penjualan',
            },
            component: () => import(/* webpackChunkName: "sale-shipping-create" */ '../views/Sale/Shipping/Form.vue'),
          },
          {
            path: ':sale_shipping_id/edit',
            name: 'SaleShippingEdit',
            meta: { title: 'Pengiriman Penjualan - Ubah Pengiriman' },
            component: () => import(/* webpackChunkName: "sale-shipping-edit" */ '../views/Sale/Shipping/Form.vue'),
          },
        ],
      },
    ],
  },
]