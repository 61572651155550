export default [
  {
    path: '/external-location',
    children: [
      {
        path: '',
        name: 'ExternalLocationIndex',
        meta: {
          title: 'Lokasi Eksternal',
          tag: 'external location lokasi eksternal',
          permission: ['manage_external_locations'],
          description: 'Halaman yang menampilkan daftar semua lokasi eksternal',
        },
        component: () => import(/* webpackChunkName: "external-location-index" */ '../views/ExternalLocation/Index.vue'),
      },
      {
        path: ':external_location_id',
        name: 'ExternalLocationView',
        meta: { title: 'Lokasi Eksternal - Tampilkan Lokasi Eksternal' },
        component: () => import(/* webpackChunkName: "external-location-view" */ '../views/ExternalLocation/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'ExternalLocationCreate',
            meta: {
              title: 'Lokasi Eksternal - Tambah Lokasi Eksternal',
              tag: 'create external location tambah lokasi eksternal',
              permission: ['manage_external_locations'],
              description: 'Halaman untuk menambah lokasi eksternal baru',
            },
            component: () => import(/* webpackChunkName: "external-location-create" */ '../views/ExternalLocation/Form.vue'),
          },
          {
            path: ':external_location_id/edit',
            name: 'ExternalLocationEdit',
            meta: { title: 'Lokasi Eksternal - Ubah Lokasi Eksternal' },
            component: () => import(/* webpackChunkName: "external-location-edit" */ '../views/ExternalLocation/Form.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/setting',
    children: [
      {
        path: '',
        name: 'SettingIndex',
        meta: {
          title: 'Pengaturan',
          tag: 'setting pengaturan',
          permission: ['manage_systems'],
          description: 'Halaman yang menampilkan daftar semua pengaturan',
        },
        component: () => import(/* webpackChunkName: "setting-index" */ '../views/SystemSetting/Index.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: ':setting_id/edit',
            name: 'SettingEdit',
            meta: { title: 'Pengaturan - Ubah Pengaturan' },
            component: () => import(/* webpackChunkName: "setting-edit" */ '../views/SystemSetting/Form.vue'),
          },
        ],
      },
    ],
  },
]