export default [
  {
    path: '/cashier',
    children: [
      {
        path: '',
        name: 'CashierIndex',
        meta: {
          title: 'Kasir',
          tag: 'cashier kasir',
          permission: ['manage_admins'],
          description: 'Halaman yang menampilkan daftar semua kasir',
        },
        component: () => import(/* webpackChunkName: "cashier-index" */ '../views/Cashier/Index.vue'),
      },
      {
        path: ':cashier_id',
        name: 'CashierView',
        meta: { title: 'Kasir - Tampilkan Kasir' },
        component: () => import(/* webpackChunkName: "cashier-view" */ '../views/Cashier/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'CashierCreate',
            meta: {
              title: 'Kasir - Tambah Kasir',
              tag: 'create cashier tambah kasir',
              permission: ['manage_admins'],
              description: 'Halaman untuk menambah kasir baru',
            },
            component: () => import(/* webpackChunkName: "cashier-create" */ '../views/Cashier/Form.vue'),
          },
        ],
      },
    ],
  },
]