<script setup>
import { ref } from 'vue'
const emit = defineEmits(['delete'])
const props = defineProps({
  customTitle: {
    type: String,
    default: 'Hapus Data',
  },
  customText: {
    type: String,
    default: 'Anda yakin ingin menghapus data ini?',
  },
  confirmButton: {
    type: String,
    default: 'Hapus',
  },
  manualClose: {
    type: Boolean,
    default: false,
  },
})

const submitting = ref(false)
const modal = ref(null)

const open = () => {
  modal.value.open()
  submitting.value = false
}
const close = () => {
  modal.value.close()
}
const confirmDelete = () => {
  emit('delete')
  submitting.value = true
  if (!props.manualClose) {
    close()
  }
}
const enableButton = () => {
  submitting.value = false
}
defineExpose({ open, close, enableButton })
</script>
<template>
  <modal-component ref="modal" :can-click-outside="!submitting">
    <template #header>
      <div class="modal-head">
        <div class="pt-4">
          <div class="text-base font-semibold capitalize">{{ customTitle }}</div>
        </div>
      </div>
    </template>
    <template #content>
      <div class="modal-body">
        <hr class="h-0 mx-8 text-center border-2 border-blue-500">
        <div class="px-8 py-4">{{ customText }}</div>
        <slot></slot>
        <div class="flex items-center justify-center space-x-4 text-sm">
          <g-button
            class="w-32 btn btn-danger"
            :type="'button'"
            :loading="submitting"
            @click="confirmDelete">
            {{ confirmButton }}
          </g-button>
          <g-button
            class="w-32 btn btn-outline"
            :type="'button'"
            :disabled="submitting"
            @click="close">
            Batal
          </g-button>
        </div>
      </div>
    </template>
  </modal-component>
</template>