export default [
  {
    path: '/branch',
    children: [
      {
        path: '',
        name: 'BranchIndex',
        meta: {
          title: 'Cabang',
          tag: 'branch cabang',
          permission: ['manage_branches'],
          description: 'Halaman yang menampilkan daftar semua cabang',
        },
        component: () => import(/* webpackChunkName: "branch-index" */ '../views/Branch/Index.vue'),
      },
      {
        path: ':branch_id',
        name: 'BranchView',
        meta: { title: 'Cabang - Tampilkan Cabang' },
        component: () => import(/* webpackChunkName: "branch-view" */ '../views/Branch/View.vue'),
      },
      {
        path: ':branch_id/change-password',
        name: 'BranchPassword',
        meta: { title: 'Cabang - Set Password' },
        component: () => import(/* webpackChunkName: "branch-password" */ '../views/Branch/Password.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'BranchCreate',
            meta: {
              title: 'Cabang - Tambah Cabang',
              tag: 'create branch tambah cabang',
              permission: ['manage_branches'],
              description: 'Halaman untuk menambah cabang baru',
            },
            component: () => import(/* webpackChunkName: "branch-create" */ '../views/Branch/Form.vue'),
          },
          {
            path: ':branch_id/edit',
            name: 'BranchEdit',
            meta: { title: 'Cabang - Ubah Cabang' },
            component: () => import(/* webpackChunkName: "branch-edit" */ '../views/Branch/Form.vue'),
          },
        ],
      },
    ],
  },
]