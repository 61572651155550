export default [
  {
    path: '/online-order',
    children: [
      {
        path: '',
        name: 'OnlineOrderIndex',
        meta: {
          title: 'Pesanan Online',
          tag: 'online order pesanan online',
          permission: ['manage_online_orders', 'view_online_order'],
          description: 'Halaman yang menampilkan daftar semua pesanan online',
        },
        component: () => import(/* webpackChunkName: "online-order-index" */ '../views/OnlineOrder/Index.vue'),
      },
      {
        path: ':online_order_id',
        name: 'OnlineOrderView',
        meta: { title: 'Pesanan Online - Tampilkan Pesanan' },
        component: () => import(/* webpackChunkName: "online-order-view" */ '../views/OnlineOrder/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'OnlineOrderCreate',
            meta: {
              title: 'Pesanan Online - Tambah Pesanan',
              tag: 'create online order tambah pesanan online',
              permission: ['manage_online_orders'],
              description: 'Halaman untuk menambah pesanan online baru',
            },
            component: () => import(/* webpackChunkName: "online-order-create" */ '../views/OnlineOrder/Form.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/online-order-payment',
    children: [
      {
        path: '',
        name: 'OnlineOrderPaymentIndex',
        meta: {
          title: 'Pembayaran Pesanan Online',
          tag: 'online order payment pembayaran pesanan online',
          permission: ['manage_online_order_payments'],
          description: 'Halaman yang menampilkan daftar semua pembayaran pesanan online',
        },
        component: () => import(/* webpackChunkName: "online-order-payment-index" */ '../views/OnlineOrder/Payment/Index.vue'),
      },
      {
        path: ':online_order_payment_id',
        name: 'OnlineOrderPaymentView',
        meta: { title: 'Pembayaran Pesanan Online - Tampilkan Pembayaran' },
        component: () => import(/* webpackChunkName: "online-order-payment-view" */ '../views/OnlineOrder/Payment/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: ':online_order_payment_id/edit',
            name: 'OnlineOrderPaymentEdit',
            meta: { title: 'Pembayaran Pesanan Online - Ubah Pembayaran' },
            component: () => import(/* webpackChunkName: "online-order-payment-edit" */ '../views/OnlineOrder/Payment/Form.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/online-order-shipping',
    children: [
      {
        path: '',
        name: 'OnlineOrderShippingIndex',
        meta: {
          title: 'Pengiriman Pesanan Online',
          tag: 'online order pesanan online',
          permission: ['manage_online_order_shippings'],
          description: 'Halaman yang menampilkan daftar semua pengiriman pesanan online',
        },
        component: () => import(/* webpackChunkName: "online-order-shipping-index" */ '../views/OnlineOrder/Shipping/Index.vue'),
      },
      {
        path: ':online_order_shipping_id',
        name: 'OnlineOrderShippingView',
        meta: { title: 'Pengiriman Pesanan Online - Tampilkan Pengiriman' },
        component: () => import(/* webpackChunkName: "online-order-shipping-view" */ '../views/OnlineOrder/Shipping/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: ':online_order_shipping_id/edit',
            name: 'OnlineOrderShippingEdit',
            meta: { title: 'Pengiriman Pesanan Online - Ubah Pengiriman' },
            component: () => import(/* webpackChunkName: "online-order-shipping-edit" */ '../views/OnlineOrder/Shipping/Form.vue'),
          },
        ],
      },
    ],
  },
]