export default {
  path: '/',
  component: () => import(/* webpackChunkName: "report" */ '../layouts/Print/Index.vue'),
  children: [
    {
      path: '/barcode/print',
      name: 'BarcodePrint',
      meta: { title: 'Cetak Barcode' },
      component: () => import(/* webpackChunkName: "print-barcode" */ '../views/Print/Label/Barcode.vue'),
    },
    {
      path: '/barcode-sales/print',
      name: 'BarcodeSalesPrint',
      meta: { title: 'Cetak Barcode Sales' },
      component: () => import(/* webpackChunkName: "print-barcode-sales" */ '../views/Print/Label/BarcodeSales.vue'),
    },
    {
      path: '/transaction/receipt',
      name: 'ReceiptPrint',
      meta: { title: 'Cetak Struk' },
      component: () => import(/* webpackChunkName: "print-receipt" */ '../views/Print/Sale/Receipt.vue'),
    },
    {
      path: '/product-in-demand/print',
      name: 'ProductInDemandPrint',
      meta: { title: 'Cetak Laporan Produk Terlaris' },
      component: () => import(/* webpackChunkName: "product-in-demand-print" */ '../views/Print/Sale/ProductInDemand.vue'),
    },
    {
      path: '/stockopname/:stockopname_id/print',
      name: 'StockopnamePrint',
      meta: { title: 'Cetak Laporan Stok Opname' },
      component: () => import(/* webpackChunkName: "stockopname-print" */ '../views/Print/Stock/Stockopname.vue'),
    },
    {
      path: '/stock-card/print',
      name: 'StockCardPrint',
      meta: { title: 'Cetak Laporan Kartu Stok' },
      component: () => import(/* webpackChunkName: "stock-card-print" */ '../views/Print/Stock/StockCard.vue'),
    },
    {
      path: '/customer/print',
      name: 'CustomerPrint',
      meta: { title: 'Cetak Data Customer' },
      component: () => import(/* webpackChunkName: "customer-print" */ '../views/Print/MasterData/Customer.vue'),
    },
    {
      path: '/supplier/print',
      name: 'SupplierPrint',
      meta: { title: 'Cetak Data Supplier' },
      component: () => import(/* webpackChunkName: "supplier-print" */ '../views/Print/MasterData/Supplier.vue'),
    },
    {
      path: '/stock/print',
      name: 'StockPrint',
      meta: { title: 'Cetak Laporan Kartu Stok' },
      component: () => import(/* webpackChunkName: "stock-print" */ '../views/Print/Stock/Index.vue'),
    },
    {
      path: '/stock-adjustment',
      children: [
        {
          path: 'print',
          name: 'StockAdjustmentPrint',
          meta: { title: 'Cetak Laporan Penyesuaian Stok' },
          component: () => import(/* webpackChunkName: "stock-adjustment-print" */ '../views/Print/Stock/StockAdjustment.vue'),
        },
        {
          path: ':stock_adjustment_id/print',
          name: 'StockAdjustmentReceiptPrint',
          meta: { title: 'Cetak Struk Penyesuaian Stok' },
          component: () => import(/* webpackChunkName: "stock-adjustment-receipt-print" */ '../views/Print/Stock/StockAdjustmentReceipt.vue'),
        },
      ],
    },
    {
      path: '/mutation',
      children: [
        {
          path: 'print',
          name: 'MutationPrint',
          meta: { title: 'Cetak Laporan Mutasi' },
          component: () => import(/* webpackChunkName: "mutation-print" */ '../views/Print/Mutation/Internal/Index.vue'),
        },
        {
          path: ':mutation_id/print',
          name: 'MutationInvoicePrint',
          meta: { title: 'Cetak Mutasi' },
          component: () => import(/* webpackChunkName: "mutation-invoice-print" */ '../views/Print/Mutation/Internal/Invoice.vue'),
        },
        {
          path: ':mutation_id/print-receipt',
          name: 'MutationInvoicePrintReceipt',
          meta: { title: 'Cetak Struk Mutasi' },
          component: () => import(/* webpackChunkName: "mutation-invoice-print-receipt" */ '../views/Print/Mutation/Internal/InvoiceReceipt.vue'),
        },
      ],
    },
    {
      path: '/mutation-external',
      children: [
        {
          path: 'print',
          name: 'MutationExternalPrint',
          meta: { title: 'Cetak Laporan Mutasi Eksternal' },
          component: () => import(/* webpackChunkName: "mutation-external-print" */ '../views/Print/Mutation/External/Index.vue'),
        },
        {
          path: ':mutation_external_id/print',
          name: 'MutationExternalInvoicePrint',
          meta: { title: 'Cetak Mutasi' },
          component: () => import(/* webpackChunkName: "mutation-invoice-print" */ '../views/Print/Mutation/External/Invoice.vue'),
        },
        {
          path: ':mutation_external_id/print-receipt',
          name: 'MutationExternalInvoicePrintReceipt',
          meta: { title: 'Cetak Struk Mutasi Eksternal' },
          component: () => import(/* webpackChunkName: "mutation-invoice-print-receipt" */ '../views/Print/Mutation/External/InvoiceReceipt.vue'),
        },
      ],
    },
    {
      path: '/purchase',
      children: [
        {
          path: 'print',
          name: 'PurchasePrint',
          meta: { title: 'Cetak Laporan Pembelian' },
          component: () => import(/* webpackChunkName: "purchase-print" */ '../views/Print/Purchase/Index.vue'),
        },
        {
          path: 'account-payable',
          name: 'AccountPayablePrint',
          meta: { title: 'Cetak Laporan Hutang Dagang' },
          component: () => import(/* webpackChunkName: "purchase-account-payable" */ '../views/Print/Purchase/AccountPayable.vue'),
        },
        {
          path: 'by-item/print',
          name: 'PurchaseByItemPrint',
          meta: { title: 'Cetak Laporan Pembelian Per Item' },
          component: () => import(/* webpackChunkName: "purchases-by-item-print" */ '../views/Print/Purchase/ByItem.vue'),
        },
        {
          path: 'by-supplier/print',
          name: 'PurchaseBySupplierPrint',
          meta: { title: 'Cetak Laporan Pembelian Per Supplier' },
          component: () => import(/* webpackChunkName: "purchases-by-supplier-print" */ '../views/Print/Purchase/BySupplier.vue'),
        },
        {
          path: 'data/print',
          name: 'PurchasesDataPrint',
          meta: { title: 'Cetak Laporan Data Pembelian' },
          component: () => import(/* webpackChunkName: "purchases-data-print" */ '../views/Print/Purchase/Data.vue'),
        },
        {
          path: 'update-barcode-branch-list',
          name: 'BranchListPrint',
          meta: { title: 'Cetak Daftar Perubahan Produk Pada Cabang' },
          component: () => import(/* webpackChunkName: "branch-list-print" */ '../views/Print/Purchase/ListStockBranchUpdateBarcode.vue'),
        },
        {
          path: 'payment',
          name: 'PurchasePaymentPrint',
          meta: { title: 'Cetak Laporan Pembayaran Pembelian' },
          component: () => import(/* webpackChunkName: "purchase-payment-print" */ '../views/Print/Purchase/Payment.vue'),
        },
        {
          path: 'retur/print',
          name: 'PurchaseReturPrint',
          meta: { title: 'Cetak Laporan Retur Pembelian' },
          component: () => import(/* webpackChunkName: "purchase-retur-print" */ '../views/Print/Purchase/Retur.vue'),
        },
        {
          path: 'summary',
          name: 'PurchaseSummaryPrint',
          meta: { title: 'Cetak Laporan Rekap Pembelian' },
          component: () => import(/* webpackChunkName: "purchase-summary-print" */ '../views/Print/Purchase/Summary.vue'),
        },
        {
          path: ':purchase_id/print',
          name: 'PurchaseInvoicePrint',
          meta: { title: 'Cetak Pembelian' },
          component: () => import(/* webpackChunkName: "purchase-print" */ '../views/Print/Purchase/Invoice.vue'),
        },
        {
          path: ':purchase_id/print-receipt',
          name: 'PurchaseInvoicePrintReceipt',
          meta: { title: 'Cetak Struk Pembelian' },
          component: () => import(/* webpackChunkName: "purchase-print-receipt" */ '../views/Print/Purchase/InvoiceReceipt.vue'),
        },
      ],
    },
    {
      path: '/purchase-retur',
      children: [
        {
          path: ':purchase_retur_id/print',
          name: 'PurchaseReturInvoicePrint',
          meta: { title: 'Cetak Retur Pembelian' },
          component: () => import(/* webpackChunkName: "purchase-retur-print" */ '../views/Print/Purchase/ReturInvoice.vue'),
        },
        {
          path: ':purchase_retur_id/print-receipt',
          name: 'PurchaseReturInvoicePrintReceipt',
          meta: { title: 'Cetak Struk Retur Pembelian' },
          component: () => import(/* webpackChunkName: "purchase-retur-print-receipt" */ '../views/Print/Purchase/ReturInvoiceReceipt.vue'),
        },
      ],
    },
    {
      path: '/old-purchase-retur',
      children: [
        {
          path: ':purchase_retur_id/print',
          name: 'OldPurchaseReturInvoicePrint',
          meta: { title: 'Cetak Retur Pembelian' },
          component: () => import(/* webpackChunkName: "purchase-retur-print" */ '../views/Print/Purchase/OldReturInvoice.vue'),
        },
        {
          path: ':purchase_retur_id/print-receipt',
          name: 'OldPurchaseReturInvoicePrintReceipt',
          meta: { title: 'Cetak Struk Retur Pembelian' },
          component: () => import(/* webpackChunkName: "purchase-retur-print-receipt" */ '../views/Print/Purchase/OldReturInvoiceReceipt.vue'),
        },
      ],
    },
    {
      path: '/sale',
      children: [
        {
          path: 'print',
          name: 'SalePrint',
          meta: { title: 'Cetak Laporan Penjualan' },
          component: () => import(/* webpackChunkName: "sale-print" */ '../views/Print/Sale/Index.vue'),
        },
        {
          path: 'data/print',
          name: 'SalesDataPrint',
          meta: { title: 'Cetak Laporan Data Penjualan' },
          component: () => import(/* webpackChunkName: "sales-data-print" */ '../views/Print/Sale/Data.vue'),
        },
        {
          path: 'global/print',
          name: 'SaleGlobalPrint',
          meta: { title: 'Cetak Laporan Penjualan Global' },
          component: () => import(/* webpackChunkName: "sale-global-print" */ '../views/Print/Sale/Global.vue'),
        },
        {
          path: 'by-card-number/print',
          name: 'SaleByCardNumberPrint',
          meta: { title: 'Cetak Laporan Penjualan Per Nomer Kartu' },
          component: () => import(/* webpackChunkName: "sale-by-card-number-print" */ '../views/Print/Sale/ByCardNumber.vue'),
        },
        {
          path: 'by-card-number-global/print',
          name: 'SaleByCardNumberPrintGlobal',
          meta: { title: 'Cetak Laporan Penjualan Per Nomer Kartu Global' },
          component: () => import(/* webpackChunkName: "sale-by-card-number-global-print" */ '../views/Print/Sale/ByCardNumberGlobal.vue'),
        },
        {
          path: 'by-category/print',
          name: 'SaleByCategoryPrint',
          meta: { title: 'Cetak Penjualan Per Kategori' },
          component: () => import(/* webpackChunkName: "sale-by-category-print" */ '../views/Print/Sale/ByCategory.vue'),
        },
        {
          path: 'by-customer/print',
          name: 'SaleByCustomerPrint',
          meta: { title: 'Cetak Laporan Penjualan Per Customer' },
          component: () => import(/* webpackChunkName: "sale-by-customer-print" */ '../views/Print/Sale/ByCustomer.vue'),
        },
        {
          path: 'by-edc-machine/print',
          name: 'SaleByEdcPrint',
          meta: { title: 'Cetak Laporan Penjualan Per Mesin EDC' },
          component: () => import(/* webpackChunkName: "sale-by-customer-print" */ '../views/Print/Sale/ByEdc.vue'),
        },
        {
          path: 'by-item/print',
          name: 'SaleByItemPrint',
          meta: { title: 'Cetak Laporan Penjualan Per Item' },
          component: () => import(/* webpackChunkName: "sale-by-item-print" */ '../views/Print/Sale/ByItem.vue'),
        },
        {
          path: 'by-sales/print',
          name: 'SaleBySalesPrint',
          meta: { title: 'Cetak Laporan Penjualan Per Sales' },
          component: () => import(/* webpackChunkName: "sale-by-sales-print" */ '../views/Print/Sale/BySales.vue'),
        },
        {
          path: 'retur/print',
          name: 'SaleReturPrint',
          meta: { title: 'Cetak Laporan Pengembalian Penjualan' },
          component: () => import(/* webpackChunkName: "sale-retur-print" */ '../views/Print/Sale/Retur.vue'),
        },
      ],
    },
    {
      path: '/shipping',
      children: [
        {
          path: 'sale/print',
          name: 'SaleShippingLabelPrint',
          meta: { title: 'Cetak Label Pengiriman' },
          component: () => import(/* webpackChunkName: "print-sale-shipping" */ '../views/Print/Label/Shipping.vue'),
        },
        {
          path: 'sale/print-mini',
          name: 'SaleShippingLabelPrintReceipt',
          meta: { title: 'Cetak Label Pengiriman' },
          component: () => import(/* webpackChunkName: "print-sale-shipping-receipt" */ '../views/Print/Label/ShippingReceipt.vue'),
        },
        {
          path: 'online-order/print',
          name: 'OnlineOrderShippingLabelPrint',
          meta: { title: 'Cetak Label Pengiriman' },
          component: () => import(/* webpackChunkName: "print-online-order-shipping" */ '../views/Print/Label/Shipping.vue'),
        },
        {
          path: 'online-order/print-mini',
          name: 'OnlineOrderShippingLabelPrintReceipt',
          meta: { title: 'Cetak Label Pengiriman' },
          component: () => import(/* webpackChunkName: "print-online-order-shipping-receipt" */ '../views/Print/Label/ShippingReceipt.vue'),
        },
      ],
    },
  ],
}