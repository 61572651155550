import * as Sentry from '@sentry/vue'

export default function (app, router) {
  if (import.meta.env.DEV) {
    return
  }

  Sentry.init({
    app,
    dsn: 'https://273c6edb14bf4d74be8dbdec42988efb@o4504999696728064.ingest.sentry.io/4504999719469056',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['pos.rain-api.test', import.meta.env.VITE_SERVER_HOST, /^\//],
      }),
    ],
    ignoreErrors: [
      // axios server common https statuses
      'Request failed with status code 401',
      'Request failed with status code 403',
      'Request failed with status code 404',
      'Request failed with status code 419',
      'Request failed with status code 422',
      'Request failed with status code 429',
      'Request failed with status code 500',
      'Request failed with status code 502',
      'Request failed with status code 503',

      // lazy load error
      'Image failed to load!',
      'Failed to fetch dynamically imported module',
      // don't know what caused this error and why is this reported to sentry
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  })
  Sentry.setTag('app_version', import.meta.env.PACKAGE_VERSION)
}