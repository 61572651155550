// https://www.npmjs.com/package/@fortawesome/vue-fontawesome

import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faAngleDown,
  faAngleRight,
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowRotateLeft,
  faArrowRotateRight,
  faArrowsRotate,
  faArrowUpArrowDown,
  faAtom,
  faBadgePercent,
  faBagShopping,
  faBallotCheck,
  faBarcodeRead,
  faBars,
  faBell,
  faBellOn,
  faBellSlash,
  faBold,
  faBox,
  faBoxCheck,
  faBoxCircleCheck,
  faBoxesStacked,
  faBracketSquare,
  faCalculatorSimple,
  faCalendarCheck,
  faCalendarDays,
  faCartPlus,
  faCartShopping,
  faCashRegister,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleDot,
  faCircleQuestion,
  faCircleXmark,
  faClipboard,
  faClipboardList,
  faClockRotateLeft,
  faCoins,
  faCopy,
  faCopyright,
  faEnvelope,
  faEnvelopeOpen,
  faEye,
  faEyeSlash,
  faFaceSadSweat,
  faFileChartColumn,
  faFileExport,
  faFileInvoiceDollar,
  faFileSpreadsheet,
  faFilterList,
  faFloppyDiskCircleArrowRight,
  faGauge,
  faGear,
  faH1,
  faHandHoldingBox,
  faHandHoldingDollar,
  faHandsHoldingDollar,
  faHorizontalRule,
  faHourglassClock,
  faHouseLaptop,
  faIdBadge,
  faIdCard,
  faImage,
  faItalic,
  faLaptop,
  faLayerGroup,
  faLightbulbExclamationOn,
  faListOl,
  faListUl,
  faLocationDot,
  faLockKeyhole,
  faMagnifyingGlass,
  faMoneyBill1,
  faMoneyBillWave,
  faMoneyCheckDollarPen,
  faMoneyCheckPen,
  faObjectsColumn,
  faPalette,
  faPenToSquare,
  faPercent,
  faPlus,
  faPrint,
  faReceipt,
  faRectangleAd,
  faRotate,
  faSackDollar,
  faScannerTouchscreen,
  faShop,
  faSpinnerThird,
  faSquare,
  faSquareCheck,
  faSquareDashed,
  faStore,
  faStrikethrough,
  faTag,
  faTags,
  faTextSlash,
  faToggleOff,
  faToggleOn,
  faTrash,
  faTruck,
  faTruckFast,
  faTruckMoving,
  faTruckRampBox,
  faUnlockKeyhole,
  faUser,
  faUserLock,
  faUserPlus,
  faUsers,
  faUserTag,
  faUserTie,
  faWarehouse,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons'

library.add(
  faAngleDown,
  faAngleRight,
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowRotateLeft,
  faArrowRotateRight,
  faArrowsRotate,
  faArrowUpArrowDown,
  faAtom,
  faBadgePercent,
  faBagShopping,
  faBallotCheck,
  faBarcodeRead,
  faBars,
  faBell,
  faBellOn,
  faBellSlash,
  faBold,
  faBox,
  faBoxCheck,
  faBoxCircleCheck,
  faBoxesStacked,
  faBracketSquare,
  faCalculatorSimple,
  faCalendarCheck,
  faCalendarDays,
  faCartPlus,
  faCartShopping,
  faCashRegister,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleDot,
  faCircleQuestion,
  faCircleXmark,
  faClipboard,
  faClipboardList,
  faClockRotateLeft,
  faCoins,
  faCopy,
  faCopyright,
  faEnvelope,
  faEnvelopeOpen,
  faEye,
  faEyeSlash,
  faFaceSadSweat,
  faFileChartColumn,
  faFileExport,
  faFileInvoiceDollar,
  faFileSpreadsheet,
  faFilterList,
  faFloppyDiskCircleArrowRight,
  faGauge,
  faGear,
  faH1,
  faHandHoldingBox,
  faHandHoldingDollar,
  faHandsHoldingDollar,
  faHorizontalRule,
  faHourglassClock,
  faHouseLaptop,
  faIdBadge,
  faIdCard,
  faImage,
  faItalic,
  faLaptop,
  faLayerGroup,
  faLightbulbExclamationOn,
  faListOl,
  faListUl,
  faLocationDot,
  faLockKeyhole,
  faMagnifyingGlass,
  faMoneyBill1,
  faMoneyBillWave,
  faMoneyCheckDollarPen,
  faMoneyCheckPen,
  faObjectsColumn,
  faPalette,
  faPenToSquare,
  faPercent,
  faPlus,
  faPrint,
  faReceipt,
  faRectangleAd,
  faRotate,
  faSackDollar,
  faScannerTouchscreen,
  faShop,
  faSquare,
  faSquareCheck,
  faSquareDashed,
  faStore,
  faStrikethrough,
  faTag,
  faTags,
  faTextSlash,
  faToggleOff,
  faToggleOn,
  faTrash,
  faTruck,
  faTruckFast,
  faTruckMoving,
  faTruckRampBox,
  faUnlockKeyhole,
  faUser,
  faUserLock,
  faUserPlus,
  faUsers,
  faUserTag,
  faUserTie,
  faWarehouse,
  faXmark,
  faSpinnerThird,
)

import {
  faCartFlatbedBoxes,
  faCircle as faCircleSolid,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleXmark as faCircleXmarkSolid,
  faExclamation,
  faKey,
  faReply,
  faShop as faShopSolid,
  faShopLock,
  faStore as faStoreSolid,
  faSquareCheck as faSquareCheckSolid,
} from '@fortawesome/pro-solid-svg-icons'
library.add(
  faCartFlatbedBoxes,
  faCircleExclamation,
  faCircleInfo,
  faCircleSolid,
  faCircleCheck,
  faCircleXmarkSolid,
  faExclamation,
  faKey,
  faReply,
  faShopSolid,
  faShopLock,
  faStoreSolid,
  faSquareCheckSolid,
)

export default {
  install (Vue) {
    Vue.component('FaIcon', FontAwesomeIcon)
    Vue.component('FaLayers', FontAwesomeLayers)
    Vue.component('FaLayersText', FontAwesomeLayersText)
  },
}