export default [
  {
    path: '/color-code',
    children: [
      {
        path: '',
        name: 'ColorCodeIndex',
        meta: {
          title: 'Kode Warna',
          tag: 'color code kode warna',
          permission: ['manage_products'],
          description: 'Halaman yang menampilkan daftar semua kode warna',
        },
        component: () => import(/* webpackChunkName: "color-code-index" */ '../views/ColorCode/Index.vue'),
      },
      {
        path: ':color_code_id',
        name: 'ColorCodeView',
        meta: { title: 'Kode Warna - Tampilkan Kode Warna' },
        component: () => import(/* webpackChunkName: "color-code-view" */ '../views/ColorCode/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'ColorCodeCreate',
            meta: {
              title: 'Kode Warna - Tambah Kode Warna',
              tag: 'create color code tambah kode warna',
              permission: ['manage_products'],
              description: 'Halaman untuk menambah kode warna baru',
            },
            component: () => import(/* webpackChunkName: "color-code-create" */ '../views/ColorCode/Form.vue'),
          },
          {
            path: ':color_code_id/edit',
            name: 'ColorCodeEdit',
            meta: { title: 'Kode Warna - Ubah Kode Warna' },
            component: () => import(/* webpackChunkName: "color-code-edit" */ '../views/ColorCode/Form.vue'),
          },
        ],
      },
    ],
  },
]