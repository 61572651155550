export default [
  {
    path: '/log',
    children: [
      {
        path: 'sync-stock-log',
        name: 'SyncStockLog',
        meta: { title: 'Log - Sync Log Stok' },
        component: () => import(/* webpackChunkName: "log-sync-stock-log" */ '../views/Log/SyncStock.vue'),
      },
      {
        path: 'exchange-rate',
        name: 'ExchangeRateLog',
        meta: { title: 'Log - Nilai Tukar' },
        component: () => import(/* webpackChunkName: "log-exchange-rate" */ '../views/Log/ExchangeRate.vue'),
      },
      {
        path: 'price-multiplier',
        name: 'PriceMultiplierLog',
        meta: { title: 'Log - Rumus Harga Jual' },
        component: () => import(/* webpackChunkName: "log-price-multiplier" */ '../views/Log/PriceMultiplier.vue'),
      },
      {
        path: 'product-price',
        name: 'ProductPriceLog',
        meta: { title: 'Log - Harga Produk' },
        component: () => import(/* webpackChunkName: "log-product-price" */ '../views/Log/ProductPrice.vue'),
      },
      {
        path: 'sale-with-minus-stock',
        name: 'SaleWithMinusStockLog',
        meta: { title: 'Log - Penjualan Dengan Stok Minus' },
        component: () => import(/* webpackChunkName: "log-sale-with-minus-stock" */ '../views/Log/SaleWithMinusStock.vue'),
      },
    ],
  },
]