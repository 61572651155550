const state = () => ({
  permissions: [],
  cashierPermissions: [
    'manage_mutations',
    'manage_sales',
    'manage_sale_shippings',
    'manage_stock_adjustments',
    'view_sale_payment',
    'view_sale_retur',
    'generate_report_customer',
    'generate_report_mutation',
    'generate_report_mutation_external',
    'generate_report_stock_adjustment',
    'generate_report_stock_card',
    'generate_report_today_transaction',
    'add_customer',
    'use_rajaongkir_api',
    'scan_stockopname',
    'search_stock',
  ],
})

const getters = {
  getCountPermission (state) {
    return state.permissions.length
  },
  getCashierPermissions (state) {
    return state.cashierPermissions
  },

  // get access to manage
  getAccessManageAdmin (state) {
    return state.permissions.includes('manage_admins')
  },
  getAccessManageBranch (state) {
    return state.permissions.includes('manage_branches')
  },
  getAccessManageCashier (state) {
    return state.permissions.includes('manage_cashiers')
  },
  getAccessManageCurrency (state) {
    return state.permissions.includes('manage_currencies')
  },
  getAccessManageDiscountProduct (state) {
    return state.permissions.includes('manage_discount_products')
  },
  getAccessManageExternalLocation (state) {
    return state.permissions.includes('manage_external_locations')
  },
  getAccessManageMember (state) {
    return state.permissions.includes('manage_members')
  },
  getAccessManageMemberAddress (state) {
    return state.permissions.includes('manage_member_addresses')
  },
  getAccessManageMutation (state) {
    return state.permissions.includes('manage_mutations')
  },
  getAccessManageMutationExternalReturn (state) {
    return state.permissions.includes('manage_mutation_external_returns')
  },
  getAccessManageOnlineOrder (state) {
    return state.permissions.includes('manage_online_orders')
  },
  getAccessManageOnlineOrderPayment (state) {
    return state.permissions.includes('manage_online_order_payments')
  },
  getAccessManageOnlineOrderShipping (state) {
    return state.permissions.includes('manage_online_order_shippings')
  },
  getAccessManageOnlineProduct (state) {
    return state.permissions.includes('manage_online_products')
  },
  getAccessManageOnlineShop (state) {
    return state.permissions.includes('manage_online_shops')
  },
  getAccessManagePayment (state) {
    return state.permissions.includes('manage_payments')
  },
  getAccessManagePoint (state) {
    return state.permissions.includes('manage_points')
  },
  getAccessManageProduct (state) {
    return state.permissions.includes('manage_products')
  },
  getAccessUpdateProduct (state) {
    return state.permissions.includes('update_products')
  },
  getAccessManagePurchase (state) {
    return state.permissions.includes('manage_purchases')
  },
  getAccessManagePurchasePayment (state) {
    return state.permissions.includes('manage_purchase_payments')
  },
  getAccessManagePurchaseRefund (state) {
    return state.permissions.includes('manage_purchase_refunds')
  },
  getAccessManagePurchaseRetur (state) {
    return state.permissions.includes('manage_purchase_returs')
  },
  getAccessManagePurchaseShipping (state) {
    return state.permissions.includes('manage_purchase_shippings')
  },
  getAccessManageSale (state) {
    return state.permissions.includes('manage_sales')
  },
  getAccessManageSalePayment (state) {
    return state.permissions.includes('manage_sale_payments')
  },
  getAccessManageSaleRetur (state) {
    return state.permissions.includes('manage_sale_returs')
  },
  getAccessManageSaleShipping (state) {
    return state.permissions.includes('manage_sale_shippings')
  },
  getAccessManageSalesPromotionGirl (state) {
    return state.permissions.includes('manage_sales_promotion_girls')
  },
  getAccessManageShipper (state) {
    return state.permissions.includes('manage_shippers')
  },
  getAccessManageStock (state) {
    return state.permissions.includes('manage_stocks')
  },
  getAccessManageStockopname (state) {
    return state.permissions.includes('manage_stockopnames')
  },
  getAccessManageStockopnameSale (state) {
    return state.permissions.includes('manage_stockopname_sales')
  },
  getAccessManageStockAdjustment (state) {
    return state.permissions.includes('manage_stock_adjustments')
  },
  getAccessManageSupplier (state) {
    return state.permissions.includes('manage_suppliers')
  },
  getAccessManageSupplierMultiplier (state) {
    return state.permissions.includes('manage_supplier_multipliers')
  },
  getAccessManageSystem (state) {
    return state.permissions.includes('manage_systems')
  },

  // get access to view
  getAccessViewCogs (state) {
    return state.permissions.includes('view_cost_of_goods_sold')
  },
  getAccessViewDashboard (state) {
    return state.permissions.includes('view_dashboard')
  },
  getAccessViewLog (state) {
    return state.permissions.includes('view_log')
  },
  getAccessViewLogSale (state) {
    return state.permissions.includes('view_log_sale')
  },
  getAccessViewMember (state) {
    return state.permissions.includes('view_member')
  },
  getAccessViewMutation (state) {
    return state.permissions.includes('view_mutation')
  },
  getAccessViewOnlineOrder (state) {
    return state.permissions.includes('view_online_order')
  },
  getAccessViewOnlineOrderPayment (state) {
    return state.permissions.includes('view_online_order_payment')
  },
  getAccessViewOnlineOrderShipping (state) {
    return state.permissions.includes('view_online_order_shipping')
  },
  getAccessViewPurchase (state) {
    return state.permissions.includes('view_purchase')
  },
  getAccessViewPurchasePayment (state) {
    return state.permissions.includes('view_purchase_payment')
  },
  getAccessViewPurchaseRefund (state) {
    return state.permissions.includes('view_purchase_refund')
  },
  getAccessViewPurchaseRetur (state) {
    return state.permissions.includes('view_purchase_retur')
  },
  getAccessViewPurchaseShipping (state) {
    return state.permissions.includes('view_purchase_shipping')
  },
  getAccessViewSale (state) {
    return state.permissions.includes('view_sale')
  },
  getAccessViewSalePayment (state) {
    return state.permissions.includes('view_sale_payment')
  },
  getAccessViewSaleRetur (state) {
    return state.permissions.includes('view_sale_retur')
  },
  getAccessViewSaleShipping (state) {
    return state.permissions.includes('view_sale_shipping')
  },
  getAccessViewStock (state) {
    return state.permissions.includes('view_stock')
  },
  getAccessViewStockBranch (state) {
    return state.permissions.includes('view_stock_branch')
  },
  getAccessViewStockopnameSale (state) {
    return state.permissions.includes('view_stockopname_sale')
  },

  // permission to delete / cancel
  getAccessCancelMutation (state) {
    return state.permissions.includes('cancel_mutation')
  },
  getAccessCancelPurchase (state) {
    return state.permissions.includes('cancel_purchase')
  },
  getAccessCancelSale (state) {
    return state.permissions.includes('cancel_sale')
  },
  getAccessDeleteProduct (state) {
    return state.permissions.includes('delete_product')
  },

  // permission to validate
  getAccessValidateTransaction (state) {
    return state.permissions.includes('validate_transaction')
  },
  getAccessConfirmPayment (state) {
    return state.permissions.includes('confirm_payment')
  },
  getAccessConfirmRefundPurchasePayment (state) {
    return state.permissions.includes('confirm_refund_purchase_payment')
  },

  // get access to generate
  getAccessGenerateBarcode (state) {
    return state.permissions.includes('generate_barcode')
  },
  getAccessGenerateChart (state) {
    return state.permissions.includes('generate_chart')
  },
  getAccessGenerateReport (state) {
    return state.permissions.includes('generate_report')
  },
  getAccessGenerateReportAccountPayable (state) {
    return state.permissions.includes('generate_report_account_payable')
  },
  getAccessGenerateReportCustomer (state) {
    return state.permissions.includes('generate_report_customer')
  },
  getAccessGenerateReportMutation (state) {
    return state.permissions.includes('generate_report_mutation')
  },
  getAccessGenerateReportMutationExternal (state) {
    return state.permissions.includes('generate_report_mutation_external')
  },
  getAccessGenerateReportInventoryValue (state) {
    return state.permissions.includes('generate_report_inventory_value')
  },
  getAccessGenerateReportProductInDemand (state) {
    return state.permissions.includes('generate_report_product_in_demand')
  },
  getAccessGenerateReportPurchase (state) {
    return state.permissions.includes('generate_report_purchase')
  },
  getAccessGenerateReportPurchaseByItem (state) {
    return state.permissions.includes('generate_report_purchase_by_item')
  },
  getAccessGenerateReportPurchaseBySupplier (state) {
    return state.permissions.includes('generate_report_purchase_by_supplier')
  },
  getAccessGenerateReportPurchasePayment (state) {
    return state.permissions.includes('generate_report_purchase_payment')
  },
  getAccessGenerateReportPurchaseRetur (state) {
    return state.permissions.includes('generate_report_purchase_retur')
  },
  getAccessGenerateReportPurchaseSummary (state) {
    return state.permissions.includes('generate_report_purchase_summary')
  },
  getAccessGenerateReportPurchasesData (state) {
    return state.permissions.includes('generate_report_purchases_data')
  },
  getAccessGenerateReportSale (state) {
    return state.permissions.includes('generate_report_sale')
  },
  getAccessGenerateReportSaleByCardNumber (state) {
    return state.permissions.includes('generate_report_sale_by_card_number')
  },
  getAccessGenerateReportSaleByCategory (state) {
    return state.permissions.includes('generate_report_sale_by_category')
  },
  getAccessGenerateReportSaleByCustomer (state) {
    return state.permissions.includes('generate_report_sale_by_customer')
  },
  getAccessGenerateReportSaleByEdc (state) {
    return state.permissions.includes('generate_report_sale_by_edc')
  },
  getAccessGenerateReportSaleByItem (state) {
    return state.permissions.includes('generate_report_sale_by_item')
  },
  getAccessGenerateReportSaleBySales (state) {
    return state.permissions.includes('generate_report_sale_by_sales')
  },
  getAccessGenerateReportSaleGlobal (state) {
    return state.permissions.includes('generate_report_sale_global')
  },
  getAccessGenerateReportSaleRetur (state) {
    return state.permissions.includes('generate_report_sale_retur')
  },
  getAccessGenerateReportSaleSummary (state) {
    return state.permissions.includes('generate_report_sale_summary')
  },
  getAccessGenerateReportSalesData (state) {
    return state.permissions.includes('generate_report_sales_data')
  },
  getAccessGenerateReportStock (state) {
    return state.permissions.includes('generate_report_stock')
  },
  getAccessGenerateReportStockAdjustment (state) {
    return state.permissions.includes('generate_report_stock_adjustment')
  },
  getAccessGenerateReportStockCard (state) {
    return state.permissions.includes('generate_report_stock_card')
  },
  getAccessGenerateReportStockLog (state) {
    return state.permissions.includes('generate_report_stock_log')
  },
  getAccessGenerateReportStockProduct (state) {
    return state.permissions.includes('generate_report_stock_product')
  },
  getAccessGenerateReportStockopname (state) {
    return state.permissions.includes('generate_report_stockopname')
  },
  getAccessGenerateReportStockopnameSale (state) {
    return state.permissions.includes('generate_report_stockopname_sale')
  },
  getAccessGenerateReportSupplier (state) {
    return state.permissions.includes('generate_report_supplier')
  },

  // get access to export data
  getAccessExportCogs (state) {
    return state.permissions.includes('export_cost_of_goods_sold')
  },
  getAccessExportPurchase (state) {
    return state.permissions.includes('export_purchase')
  },
  getAccessExportSale (state) {
    return state.permissions.includes('export_sale')
  },

  // get access to do specific task
  getAccessAddCustomer (state) {
    return state.permissions.includes('add_customer')
  },
  getAccessAddSaleShipping (state) {
    return state.permissions.includes('add_sale_shippings')
  },
  getAccessChangeMutationTimestamp (state) {
    return state.permissions.includes('change_mutation_timestamp')
  },
  getAccessDecreaseStock (state) {
    return state.permissions.includes('decrease_stock')
  },
  getAccessUseRajaongkirApi (state) {
    return state.permissions.includes('use_rajaongkir_api')
  },
  getAccessScanStockopname (state) {
    return state.permissions.includes('scan_stockopname')
  },
  getAccessSearchStock (state) {
    return state.permissions.includes('search_stock')
  },
}

const actions = {
  savePermission ({ commit }, permissions) {
    commit('setPermissions', permissions)
  },
}

const mutations = {
  setPermissions (state, permissions) {
    state.permissions = permissions
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}