<script setup>
import { inject, ref, watch } from 'vue'

const props = defineProps({
  placeholder: {
    type: String,
    required: true,
  },
})
const emit = defineEmits(['keydown'])

const router = inject('router')
const route = inject('route')

const debounceTimeout = ref(null)
const textFilter = ref(route.query.filter ?? undefined)

watch(() => textFilter.value, (value) => {
  clearTimeout(debounceTimeout.value)
  debounceTimeout.value = setTimeout(() => {
    const filter = value == '' ? undefined : value
    router.push({ ...route, query: { ...route.query, filter } })
  }, 100)
})
const keydownEnter = () => {
  emit('keydown')
}
</script>

<template>
  <div class="flex relative items-center pr-4 max-w-fit text-sm w-full bg-white border shadow border-gray-300 rounded focus:outline-none">
    <fa-icon :icon="['far', 'magnifying-glass']" class="pl-2 text-gray-300"/>
    <input
      v-model="textFilter"
      class="w-full p-2 focus:outline-none rounded"
      :placeholder="props.placeholder"
      @keydown.enter="keydownEnter">
    <button v-if="route.query.filter" class="absolute right-0 text-gray-500" @click="textFilter=''">
      <fa-icon :icon="['far', 'xmark']" size="lg" class="mr-2"/>
    </button>
  </div>
</template>