export default [
  {
    path: '/account',
    component: () => import(/* webpackChunkName: "account-" */ '../layouts/Account/Index.vue'),
    children: [
      {
        path: 'profile',
        name: 'Profile',
        meta: {
          title: 'Profil',
          tag: 'profil',
          permission: [],
          description: 'Halaman yang menampilkan profil dari pengguna',
        },
        component: () => import(/* webpackChunkName: "account--profile" */ '../views/Account/Profile.vue'),
      },
      {
        path: 'notification',
        name: 'Notification',
        meta: {
          title: 'Notifikasi',
          tag: 'notif',
          permission: [],
          description: 'Halaman yang menampilkan daftar notifikasi',
        },
        component: () => import(/* webpackChunkName: "account-notification" */ '../views/Account/Notification.vue'),
      },
      {
        path: 'change-password',
        name: 'UpdatePassword',
        meta: {
          title: 'Ubah Password',
          tag: 'password',
          permission: [],
          description: 'Halaman untuk mengganti password',
        },
        component: () => import(/* webpackChunkName: "account-change-password" */ '../views/Account/UpdatePassword.vue'),
      },
    ],
  },
]