export default [
  {
    path: '/report',
    children: [
      {
        path: 'purchase',
        children: [
          {
            path: 'account-payable',
            name: 'ReportAccountPayable',
            meta: { title: 'Laporan Hutang Dagang' },
            component: () => import(/* webpackChunkName: "report-account-payable" */ '../views/Report/Purchase/AccountPayable/Index.vue'),
          },
          {
            path: 'by-item',
            name: 'ReportPurchaseByItem',
            meta: { title: 'Laporan Pembelian Per Item' },
            component: () => import(/* webpackChunkName: "report-purchases-by-item" */ '../views/Report/Purchase/ByItem/Index.vue'),
          },
          {
            path: 'by-nota',
            name: 'ReportPurchaseByNota',
            meta: { title: 'Laporan Pembelian Per Nota' },
            component: () => import(/* webpackChunkName: "report-purchases-by-nota" */ '../views/Report/Purchase/ByNota/Index.vue'),
          },
          {
            path: 'by-supplier',
            name: 'ReportPurchaseBySupplier',
            meta: { title: 'Laporan Pembelian Per Supplier' },
            component: () => import(/* webpackChunkName: "report-purchases-by-supplier" */ '../views/Report/Purchase/BySupplier/Index.vue'),
          },
          {
            path: 'data',
            name: 'ReportPurchasesData',
            meta: { title: 'Laporan Data Pembelian' },
            component: () => import(/* webpackChunkName: "report-purchases-data" */ '../views/Report/Purchase/Data/Index.vue'),
          },
          {
            path: 'payment',
            name: 'ReportPurchasePayment',
            meta: { title: 'Laporan Pembayaran Pembelian' },
            component: () => import(/* webpackChunkName: "report-purchase-payment" */ '../views/Report/Purchase/Payment/Index.vue'),
          },
          {
            path: 'retur',
            name: 'ReportPurchaseRetur',
            meta: { title: 'Laporan Retur Pembelian' },
            component: () => import(/* webpackChunkName: "report-purchase-retur" */ '../views/Report/Purchase/Retur/Index.vue'),
          },
          {
            path: 'summary',
            name: 'ReportPurchaseSummary',
            meta: { title: 'Laporan Rekap Pembelian' },
            component: () => import(/* webpackChunkName: "report-purchases-data" */ '../views/Report/Purchase/Summary/Index.vue'),
          },
        ],
      },
      {
        path: 'sale',
        children: [
          {
            path: 'by-card-number',
            name: 'ReportSaleByCardNumber',
            meta: { title: 'Laporan Penjualan Per Nomer Kartu' },
            component: () => import(/* webpackChunkName: "report-sale-by-category" */ '../views/Report/Sale/ByCardNumber/Index.vue'),
          },
          {
            path: 'by-category',
            name: 'ReportSaleByCategory',
            meta: { title: 'Laporan Penjualan Per Kategori' },
            component: () => import(/* webpackChunkName: "report-sale-by-category" */ '../views/Report/Sale/ByCategory/Index.vue'),
          },
          {
            path: 'by-customer',
            name: 'ReportSaleByCustomer',
            meta: { title: 'Laporan Penjualan Per Customer' },
            component: () => import(/* webpackChunkName: "report-sale-by-category" */ '../views/Report/Sale/ByCustomer/Index.vue'),
          },
          {
            path: 'by-edc-machine',
            name: 'ReportSaleByEdc',
            meta: { title: 'Laporan Penjualan Per Mesin Edc' },
            component: () => import(/* webpackChunkName: "report-sale-by-edc" */ '../views/Report/Sale/ByEdc/Index.vue'),
          },
          {
            path: 'by-invoice',
            name: 'ReportSaleByInvoice',
            meta: { title: 'Laporan Penjualan Per Nota' },
            component: () => import(/* webpackChunkName: "report-sale-by-invoice" */ '../views/Report/Sale/ByInvoice/Index.vue'),
          },
          {
            path: 'by-item',
            name: 'ReportSaleByItem',
            meta: { title: 'Laporan Penjualan Per Produk' },
            component: () => import(/* webpackChunkName: "report-sale-by-category" */ '../views/Report/Sale/ByItem/Index.vue'),
          },
          {
            path: 'by-sales',
            name: 'ReportSaleBySales',
            meta: { title: 'Laporan Penjualan Per Produk' },
            component: () => import(/* webpackChunkName: "report-sale-by-category" */ '../views/Report/Sale/BySales/Index.vue'),
          },
          {
            path: 'data',
            name: 'ReportSalesData',
            meta: { title: 'Laporan Data Penjualan' },
            component: () => import(/* webpackChunkName: "report-sales-data" */ '../views/Report/Sale/Data/Index.vue'),
          },
          {
            path: 'global',
            name: 'ReportSaleGlobal',
            meta: { title: 'Laporan Penjualan Global' },
            component: () => import(/* webpackChunkName: "report-sale-global" */ '../views/Report/Sale/Global/Index.vue'),
          },
          {
            path: 'product-in-demand',
            name: 'ReportProductInDemand',
            meta: { title: 'Laporan Produk Terlaris' },
            component: () => import(/* webpackChunkName: "report-product-in-demand" */ '../views/Report/Sale/ProductInDemand/Index.vue'),
          },
          {
            path: 'retur',
            name: 'ReportSaleRetur',
            meta: { title: 'Laporan Retur Penjualan' },
            component: () => import(/* webpackChunkName: "report-sale-retur" */ '../views/Report/Sale/Retur/Index.vue'),
          },
          {
            path: 'summary',
            name: 'ReportSaleSummary',
            meta: { title: 'Laporan Rangkuman Penjualan' },
            component: () => import(/* webpackChunkName: "report-sale-summary" */ '../views/Report/Sale/Summary/Index.vue'),
          },
        ],
      },
      {
        path: 'stock',
        children: [
          {
            path: 'log',
            name: 'ReportStock',
            meta: { title: 'Laporan Stok Barang' },
            component: () => import(/* webpackChunkName: "report-log" */ '../views/Report/Stock/Log/Index.vue'),
          },
          {
            path: 'stock-summary',
            name: 'ReportStockSummary',
            meta: { title: 'Laporan Rekap Stok Barang' },
            component: () => import(/* webpackChunkName: "report-log-summary" */ '../views/Report/Stock/LogSummary/Index.vue'),
          },
          {
            path: 'adjustment',
            name: 'ReportStockAdjustment',
            meta: { title: 'Laporan Penyesuaian Stok' },
            component: () => import(/* webpackChunkName: "report-stock-adjustment" */ '../views/Report/Stock/Adjustment/Index.vue'),
          },
          {
            path: 'mutation',
            name: 'ReportMutation',
            meta: { title: 'Laporan Mutasi' },
            component: () => import(/* webpackChunkName: "report-mutation" */ '../views/Report/Stock/Mutation/Index.vue'),
          },
          {
            path: 'mutation-external',
            name: 'ReportMutationExternal',
            meta: { title: 'Laporan Mutasi Eksternal' },
            component: () => import(/* webpackChunkName: "report-mutation" */ '../views/Report/Stock/MutationExternal/Index.vue'),
          },
          {
            path: 'stock-card',
            name: 'ReportStockCard',
            meta: { title: 'Laporan Kartu Stok' },
            component: () => import(/* webpackChunkName: "report-stock-card" */ '../views/Report/Stock/Card/Index.vue'),
          },
          {
            path: 'inventory-value',
            name: 'ReportInventoryValue',
            meta: { title: 'Laporan Nilai Inventaris' },
            component: () => import(/* webpackChunkName: "report-inventory-value" */ '../views/Report/Stock/InventoryValue/Index.vue'),
          },
          {
            path: 'stock-product',
            name: 'ReportStockProduct',
            meta: { title: 'Laporan Stok Master Produk' },
            component: () => import(/* webpackChunkName: "report-stock-card" */ '../views/Report/Stock/ProductQuantity/Index.vue'),
          },
          {
            path: 'stockopname',
            name: 'ReportStockopname',
            meta: { title: 'Laporan Stok Opname' },
            component: () => import(/* webpackChunkName: "report-stockopname" */ '../views/Report/Stock/Stockopname/Index.vue'),
          },
        ],
      },
    ],
  },
]