import { createStore } from 'vuex'
import changeLog from './modules/changeLog'
import permissions from './modules/permissions'
import route from './modules/route'
import multiselectList from './modules/multiselectList'
export default createStore({
  state: {
    showMobileSidebar: false,
    loginState: false,
    menu: 'Dashboard',
    mainMenu: 'Dashboard',
    breadcrumb: '',
    title: '',
    formPoint: '',
    role: '',
    notification: {},
    todayNotifications: [],
    userId: null,
    userName: '',
    branch: {},
    offlinePaymentMethod: [],
    templateText: {
      new_notification: 'Anda mendapatkan notifkasi baru.',
      err_connection: 'Terdapat gangguan pada koneksi ke server.',
      server_error: 'Terdapat kesalahan pada server. Segera hubungi Gradin untuk segera ditangani.',
      show_cached: 'Data yang ditampilkan adalah data yang tersimpan dalam cache. Data bisa berbeda dengan data yang tersimpan dalam database saat ini.',
    },
    labelCode: [],
    otpThrottle: {
      request: 90,
      validate: 150,
    },
    stockopname: null,
    offlineMode: false,
    onlineStatus: null,
    warehouseBranch: 'GUDANG',
    onlineBranch: 'ONLINE_SHOP',
    saleType: {
      retail: 'Retail',
      online: 'Online',
    },
    returSolution: {
      exchange: 'Tukar barang',
      subtract: 'Kurangi tagihan',
      discount: 'Diskon pembelian selanjutnya / tukar barang',
      refund: 'Pengembalian dana pembelian',
    },
    mutationStatus: {
      finished: {
        class: 'mutation-finished',
        label: 'Selesai',
      },
      process: {
        class: 'mutation-process',
        label: 'Proses',
      },
      problem: {
        class: 'mutation-problem',
        label: 'Problem',
      },
      canceled: {
        class: 'mutation-canceled',
        label: 'Dibatalkan',
      },
    },
    mutationType: {
      in: 'Mutasi Masuk',
      out: 'Mutasi Keluar',
    },
    paymentVisibility: {
      shop: 'Toko Retail',
      online: 'Toko Online',
      purchase: 'Pembelian Stock',
    },
    stockAdjustmentType: {
      increase: 'Penambahan Stok',
      decrease: 'Pengurangan Stok',
    },
    maxStock: 999,
    notificationType: {
      info: {
        text: 'notification-text-primary',
        color: 'notification-backgroud-primary',
        iconColor: 'notification-icon-primary',
        button: 'btn-primary',
        icon: 'circle-info',
      },
      success: {
        text: 'notification-text-success',
        color: 'notification-backgroud-success',
        iconColor: 'notification-icon-success',
        button: 'btn-success',
        icon: 'circle-check',
      },
      warning: {
        text: 'notification-text-warning',
        color: 'notification-backgroud-warning',
        iconColor: 'notification-icon-warning',
        button: 'btn-warning',
        icon: 'circle-exclamation',
      },
      danger: {
        text: 'notification-text-danger',
        color: 'notification-backgroud-danger',
        iconColor: 'notification-icon-danger',
        button: 'btn-danger',
        icon: 'circle-xmark',
      },
    },
    priceChangeVia: {
      'manual': 'Manual',
      'purchase': 'Pembelian',
    },
  },
  mutations: {
    changeLoginState (state, value) {
      state.loginState = value
    },
    toggleMobileSidebar (state, value) {
      state.showMobileSidebar = value
    },
    changeMenu (state, menu) {
      state.menu = menu.active
      state.mainMenu = menu.main
    },
    changeHeader (state, header) {
      state.title = header.title
      state.breadcrumb = header.breadcrumb
    },
    changeFormPoint (state, form) {
      state.formPoint = form
    },
    setRole (state, role) {
      state.role = role
    },
    setUserId (state, userId) {
      state.userId = userId
    },
    setUserName (state, name) {
      state.userName = name
    },
    setBranch (state, branch) {
      state.branch = branch
    },
    setNotification (state, notification) {
      state.notification = notification
    },
    setTodayNotification (state, todayNotifications) {
      state.todayNotifications = todayNotifications
    },
    insertTodayNotification (state, newNotification) {
      state.todayNotifications.splice(0, 0, newNotification)
    },
    invoiceIncrement (state) {
      state.branch.invoice_increment++
    },
    setOfflinePaymentMethod (state, offlinePaymentMethod) {
      state.offlinePaymentMethod = offlinePaymentMethod
    },
    setLabelCode (state, code) {
      state.labelCode = code.split('')
    },
    setStockopname (state, stockopname) {
      state.stockopname = stockopname
    },
    setMaxStock (state, max) {
      state.maxStock = max
    },
  },
  getters: {
    getReturSolution: (state) => (status = null) => {
      if (status) {
        return state.returSolution[status]
      }
      return 'Belum ditentukan'
    },
  },
  actions: {
    checkOnlineStatus (state) {
      state.onlineStatus = setInterval(() => {
        this.state.offlineMode = !window.navigator.onLine
      }, 1000)
    },
    stopCheckOnlineStatus (state) {
      clearInterval(state.onlineStatus)
    },
  },
  modules: {
    changeLog,
    permissions,
    route,
    multiselectList,
  },
})