import axios from 'axios'

const state = () => ({
  startedYear: 2023,
  months: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'],
  branches: [],
  brands: [],
  categories: [],
  currencies: [],
  edcMachines: [],
  externalLocations: [],
  paymentMethods: [],
  personInCharges: [],
  productGroups: [],
  roles: [],
  shippers: [],
  shippersWithCode: [],
  suppliers: [],
  visibilities: [],
})

const getters = {
  getListOfYear (state) {
    let optionsYear = []
    for (let year = (new Date().getFullYear()); year >= state.startedYear; year--) {
      optionsYear.push(
        {
          value: year,
          label: year,
        })
    }
    return optionsYear
  },
  getMonth: (state) => (index) => {
    return state.months[index]
  },
  getListOfMonth (state) {
    return state.months.map((month, index) => {
      return {
        value: index + 1,
        label: month,
      }
    })
  },
  getListStatisticRange () {
    return [
      {
        value: 'yearly',
        label: 'Tahunan',
      },
      {
        value: 'monthly',
        label: 'Bulanan',
      },
      {
        value: 'daily',
        label: 'Harian',
      },
      {
        value: 'range',
        label: 'Rentang Tanggal',
      },
    ]
  },
  getListSaleType () {
    return [
      {
        value: 'retail',
        label: 'Retail',
      },
      {
        value: 'online',
        label: 'Online',
      },
    ]
  },
  getListVia () {
    return [
      {
        value: 'manual',
        label: 'Manual',
      },
      {
        value: 'purchase',
        label: 'Pembelian',
      },
    ]
  },
  getListAdjustmentType () {
    return [
      {
        value: 'add',
        label: 'Penambahan',
      },
      {
        value: 'sub',
        label: 'Pengurangan',
      },
    ]
  },
  getBranches: (state) => (exclude = []) => {
    return state.branches.filter(item => !exclude.includes(item.object.code))
  },
  getBrands (state) {
    return state.brands
  },
  getCategories (state) {
    return state.categories
  },
  getCurrencies: (state) => (onlyForeign = false) => {
    return onlyForeign ? state.currencies.filter(item => !['IDR'].includes(item.value)) : state.currencies
  },
  getEdcMachines (state) {
    return state.edcMachines
  },
  getExternalLocations (state) {
    return state.externalLocations
  },
  getPaymentMethods: (state) => (visibility = null) => {
    if (visibility) {
      return state.paymentMethods.filter(item => item.visibilities.includes(visibility))
    }
    return state.paymentMethods
  },
  getPersonInCharges (state) {
    return state.personInCharges
  },
  getProductGroups (state) {
    return state.productGroups
  },
  getRoles (state) {
    return state.roles
  },
  getShippers (state) {
    return state.shippers
  },
  getShippersWithCode (state) {
    return state.shippersWithCode
  },
  getSuppliers (state) {
    return state.suppliers
  },
  getVisibilities (state) {
    return state.visibilities
  },
}

const actions = {
  async fetchBranches ({ commit }) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_URL_API + '/fetch/branch',
      )
      const branches = response.data.map((item) => {
        return { value: item.id, label: item.name, object: item, disabled: false, selected: false }
      })
      commit('setBranches', branches)
    } finally {}
  },
  async fetchBrands ({ commit }) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_URL_API + '/fetch/brand',
      )
      const brands = response.data.map((item) => {
        return { value: item.id, label: item.name, icon: item.logo_path, object: item }
      })
      commit('setBrands', brands)
    } finally {}
  },
  async fetchCategories ({ commit }) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_URL_API + '/fetch/category',
      )
      const categories = response.data.map((item) => {
        return { value: item.id, label: item.label, disabled: false }
      })
      commit('setCategories', categories)
    } finally {}
  },
  async fetchCurrencies ({ commit }) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_URL_API + '/fetch/currency',
      )
      let currencies = [
        {
          value: 'IDR',
          label: 'Indonesia Rupiah',
          rate: 1,
        },
      ]
      currencies = currencies.concat(response.data.map((item) => {
        return { value: item.code, label: item.name, rate: item.exchange_rate }
      }))
      commit('setCurrencies', currencies)
    } finally {}
  },
  async fetchEdcMachines ({ commit, rootState }) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_URL_API + '/fetch/edcMachine?branch=' + rootState.branch.id,
      )
      const edcMachines = response.data.map((item) => {
        return { value: item.id, label: item.name, object: item }
      })
      commit('setEdcMachines', edcMachines)
    } finally {}
  },
  async fetchExternalLocations ({ commit }) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_URL_API + '/fetch/externalLocation',
      )
      const externalLocations = response.data.map((item) => {
        return { value: item.id, label: item.name, object: item }
      })
      commit('setExternalLocations', externalLocations)
    } finally {}
  },
  async fetchPaymentMethods ({ commit, rootState }) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_URL_API + '/fetch/paymentMethod' + (rootState.branch ? '?branch=' + rootState.branch.id : ''),
      )
      const paymentMethods = response.data.filter(item => item.name !== 'Coin').map((item) => {
        return {
          value: item.id,
          label: item.label,
          visibilities: item.visibilities.map(item => item.visibility),
          object: item,
          disabled: false,
        }
      })
      commit('setPaymentMethods', paymentMethods)
    } finally {}
  },
  async fetchPersonInCharges ({ commit, rootState }) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_URL_API + '/fetch/cashier?active=true&branch=' + rootState.branch.id,
      )
      const personInCharges = response.data.map((item) => {
        return { value: item.user_id, label: item.user.name, object: item.user }
      })
      commit('setPersonInCharges', personInCharges)
    } finally {}
  },
  async fetchProductGroups ({ commit }) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_URL_API + '/fetch/productGroup',
      )
      const productGroups = response.data.map((item) => {
        return { value: item.id, label: item.name, object: item }
      })
      commit('setProductGroups', productGroups)
    } finally {}
  },
  async fetchRoles ({ commit }) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_URL_API + '/fetch/role',
      )
      const roles = response.data.map((item) => {
        return { value: item.name, label: item.name, object: item }
      })
      commit('setRoles', roles)
    } finally {}
  },
  async fetchShippers ({ commit }) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_URL_API + '/fetch/shipper',
      )
      const shippers = response.data.map((item) => {
        return { value: item.id, label: item.name }
      })
      commit('setShippers', shippers)
    } finally {}
  },
  async fetchShippersWithCodeOnly ({ commit }) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_URL_API + '/fetch/shipper?code=true',
      )
      const shippers = response.data.map((item) => {
        return { value: item.code, label: item.name }
      })
      commit('setShippersWithCode', shippers)
    } finally {}
  },
  async fetchSuppliers ({ commit }) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_URL_API + '/fetch/supplier',
      )
      const suppliers = response.data.map((item) => {
        return { value: item.id, label: item.name, object: item }
      })
      commit('setSuppliers', suppliers)
    } finally {}
  },
  async fetchVisibilities ({ commit }) {
    try {
      const response = await axios.get(
        import.meta.env.VITE_URL_API + '/fetch/visibility',
      )
      const visibilities = response.data.map((item) => {
        return { value: item.id, label: item.label, icon: item.icon }
      })
      commit('setVisibilities', visibilities)
    } finally {}
  },
}

const mutations = {
  setBranches (state, branches) {
    state.branches = branches
  },
  setBrands (state, brands) {
    state.brands = brands
  },
  setCategories (state, categories) {
    state.categories = categories
  },
  setCurrencies (state, currencies) {
    state.currencies = currencies
  },
  setEdcMachines (state, edcMachines) {
    state.edcMachines = edcMachines
  },
  setExternalLocations (state, externalLocations) {
    state.externalLocations = externalLocations
  },
  setPaymentMethods (state, paymentMethods) {
    state.paymentMethods = paymentMethods
  },
  setPersonInCharges (state, personInCharges) {
    state.personInCharges = personInCharges
  },
  setProductGroups (state, productGroups) {
    state.productGroups = productGroups
  },
  setRoles (state, roles) {
    state.roles = roles
  },
  setShippers (state, shippers) {
    state.shippers = shippers
  },
  setShippersWithCode (state, shippers) {
    state.shippersWithCode = shippers
  },
  setSuppliers (state, suppliers) {
    state.suppliers = suppliers
  },
  setVisibilities (state, visibilities) {
    state.visibilities = visibilities
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}