export default [
  {
    path: '/user',
    children: [
      {
        path: '',
        name: 'UserIndex',
        meta: {
          title: 'Pengguna',
          tag: 'user admin pengguna',
          permission: ['manage_admins'],
          description: 'Halaman yang menampilkan daftar semua pengguna internal Rain',
        },
        component: () => import(/* webpackChunkName: "User-index" */ '../views/User/Index.vue'),
      },
      {
        path: ':user_id',
        name: 'UserView',
        meta: { title: 'Pengguna - Tampilkan Pengguna' },
        component: () => import(/* webpackChunkName: "User-view" */ '../views/User/View.vue'),
      },
      {
        path: ':user_id/change-password',
        name: 'UserUpdatePassword',
        meta: { title: 'Pengguna - Ubah Password' },
        component: () => import(/* webpackChunkName: "User-view" */ '../views/User/UpdatePasswordByAdmin.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'UserCreate',
            meta: {
              title: 'Pengguna - Tambah Pengguna',
              tag: 'create user admin tambah pengguna',
              permission: ['manage_admins'],
              description: 'Halaman untuk menambah pengguna internal Rain baru',
            },
            component: () => import(/* webpackChunkName: "admin-create" */ '../views/User/Form.vue'),
          },
          {
            path: ':user_id/edit',
            name: 'UserUpdate',
            meta: { title: 'Pengguna - Ubah Pengguna' },
            component: () => import(/* webpackChunkName: "User-view" */ '../views/User/Form.vue'),
          },
        ],
      },
    ],
  },
]