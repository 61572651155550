<script setup>
import { ref } from 'vue'
const emit = defineEmits(['closeModal'])
defineProps({
  customText: {
    required: false,
    type: String,
    default: 'Sistem sedang bekerja membuat laporan.',
  },
})
const modal = ref(null)

const open = () => {
  modal.value.open()
}
const close = () => {
  modal.value.close()
}
const closedModal = () => {
  emit('closeModal')
}
defineExpose({ open, close })
</script>
<template>
  <modal-component ref="modal" :can-click-outside="false" @closed="closedModal">
    <template #header>
      <div class="flex h-32 p-4 bg-white rounded">
        <div class="w-16 px-4 my-4 text-sm text-blue-500">
          <fa-layers class="p-8">
            <fa-icon :icon="['far', 'circle']" size="4x"/>
            <fa-icon :icon="['far', 'rotate']" size="2x" spin/>
          </fa-layers>
        </div>
        <div class="px-8 py-4">
          <div class="text-base">Mohon tunggu.</div>
          <h4 class="text-xs text-gray-400">{{ customText }}</h4>
        </div>
      </div>
    </template>
  </modal-component>
</template>