export default [
  {
    path: '/shipper',
    children: [
      {
        path: '',
        name: 'ShipperIndex',
        meta: {
          title: 'Jasa Pengiriman',
          tag: 'shipper jasa pengiriman kurir',
          permission: ['manage_shippers'],
          description: 'Halaman yang menampilkan daftar semua jasa pengiriman',
        },
        component: () => import(/* webpackChunkName: "shipper-index" */ '../views/Shipper/Index.vue'),
      },
      {
        path: ':shipper_id',
        name: 'ShipperView',
        meta: { title: 'Jasa Pengiriman - Tampilkan Jasa Pengiriman' },
        component: () => import(/* webpackChunkName: "shipper-view" */ '../views/Shipper/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'ShipperCreate',
            meta: {
              title: 'Jasa Pengiriman - Tambah Jasa Pengiriman',
              tag: 'create shipper tambah jasa pengiriman kurir',
              permission: ['manage_shippers'],
              description: 'Halaman untuk menambah jasa pengiriman baru',
            },
            component: () => import(/* webpackChunkName: "shipper-create" */ '../views/Shipper/Form.vue'),
          },
          {
            path: ':shipper_id/edit',
            name: 'ShipperUpdate',
            meta: { title: 'Jasa Pengiriman - Ubah Jasa Pengiriman' },
            component: () => import(/* webpackChunkName: "shipper-edit" */ '../views/Shipper/Form.vue'),
          },
        ],
      },
    ],
  },
  {
    path: 'shipping-rate',
    name: 'ShippingRate',
    meta: {
      title: 'Cek Ongkos Kirim',
      tag: 'shipping rate cek ongkos kirim ongkir',
      permission: ['use_rajaongkir_api'],
      description: 'Halaman untuk cek ongkos kirim',
    },
    component: () => import(/* webpackChunkName: "shipper-shipping-rate" */ '../views/Shipper/ShippingRate.vue'),
  },
]