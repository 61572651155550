export default [
  {
    path: '/role-permission',
    children: [
      {
        path: '',
        name: 'RolePermissionIndex',
        meta: {
          title: 'Peran & Hak Akses',
          tag: 'role permission peran hak akses',
          permission: ['manage_admins'],
          description: 'Halaman yang menampilkan daftar semua peran dan hak akses',
        },
        component: () => import(/* webpackChunkName: "role-permission-index" */ '../views/RolePermission/Index.vue'),
      },
      {
        path: ':role_id',
        name: 'RolePermissionView',
        meta: { title: 'Peran & Hak Akses - Tampilkan Peran & Hak Akses' },
        component: () => import(/* webpackChunkName: "role-permission-view" */ '../views/RolePermission/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'RolePermissionCreate',
            meta: {
              title: 'Peran & Hak Akses - Tambah Peran & Hak Akses',
              tag: 'create role permission tambah peran hak akses',
              permission: ['manage_purchase_returs', 'view_purchase_retur'],
              description: 'Halaman untuk menambah peran baru dan hak aksesnya',
            },
            component: () => import(/* webpackChunkName: "role-permission-edit" */ '../views/RolePermission/Form.vue'),
          },
          {
            path: ':role_id/edit',
            name: 'RolePermissionEdit',
            meta: { title: 'Peran & Hak Akses - Ubah Peran & Hak Akses' },
            component: () => import(/* webpackChunkName: "role-permission-edit" */ '../views/RolePermission/Form.vue'),
          },
        ],
      },
    ],
  },
]