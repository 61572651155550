const state = () => ({
  logs: [
    // format log :
    // version => (string) version of app
    // date => (string date) date when version is released yyyy-mm-dd
    // update => (array of string) list of update
    // improvement => (array of string) list of improvement
    // dev => (array of string) list of change in technical language. for devs only
    {
      version: '1.13.03',
      date: '2023-06-21',
      update: [],
      improvement: [
        'Menambahkan tombol export pada laporan stok barang yang memakai plugin baru',
      ],
      dev: [],
    },
    {
      version: '1.13.02',
      date: '2023-06-12',
      update: [],
      improvement: [
        'Minor fix',
      ],
      dev: [
        'Laporan stok barang menggunakan format data yang baru',
      ],
    },
    {
      version: '1.13.01',
      date: '2023-06-01',
      update: [],
      improvement: [
        'Minor fix',
      ],
      dev: [],
    },
    {
      version: '1.13.00',
      date: '2023-05-31',
      update: [
        'Menambahkan menu pencarian',
      ],
      improvement: [
        'Minor improvement dalam menerima mutasi',
      ],
      dev: [
        'Sebagian komponen vue sudah menggunakan setup script',
        'Tidak ada lagi request API yang menggunakan parameter request_get',
      ],
    },
    {
      version: '1.12.03',
      date: '2023-05-09',
      update: [],
      improvement: [
        'Menambahkan export bulk untuk laporan dengan data yang cukup besar',
      ],
      dev: [],
    },
    {
      version: '1.12.02',
      date: '2023-05-02',
      update: [],
      improvement: [
        'Menambahkan daftar item yang sudah pernah diretur pada halaman detail penjualan',
        'Admin bisa menghapus penjualan dengan menggunakan password akunnya, untuk penjualan 7 hari ke belakang',
      ],
      dev: [],
    },
    {
      version: '1.12.01',
      date: '2023-05-02',
      update: [],
      improvement: [
        'Menambahkan kalkulasi netto pada laporan penjualan global',
        'Perbaikan kalkulasi laporan data penjualan dan rekap penjualan',
        'Minor fix',
      ],
      dev: [],
    },
    {
      version: '1.12.00',
      date: '2023-04-28',
      update: [
        'Menambahkan laporan rekap stok barang',
        'Menambahkan export laporan stok barang untuk semua cabang',
        'Menambahkan simpan saldo akhir dari laporan stok barang',
      ],
      improvement: [
        'Perbaikan untuk export laporan stok barang dengan stok banyak seperti gudang',
        'Pembaharuan tampilan pada halaman laporan stok barang',
      ],
      dev: [],
    },
    {
      version: '1.11.01',
      date: '2023-04-25',
      update: [],
      improvement: [
        'Menambahkan opsi untuk export laporan stok barang semua cabang atau hanya cabang terpilih',
      ],
      dev: [],
    },
    {
      version: '1.11.00',
      date: '2023-04-25',
      update: [
        'Menambahkan cetak retur pembelian lama',
      ],
      improvement: [
        'Menambahkan kolom input harga pada retur pembelian lama',
      ],
      dev: [
        'Fix problem reset page karena tidak bisa trigger route push 2x. Solusi dengan cara menmbatalkan trigger pertama, lalu mentrigger kembali pada onBeforeRouteUpdate di layout',
      ],
    },
    {
      version: '1.10.00',
      date: '2023-04-18',
      update: [
        'Menambahkan pengembalian mutasi eksternal',
      ],
      improvement: [
        'Opmisasi kalkulasi laporan stok barang',
      ],
      dev: [
        'Fitur hapus pengembalian mutasi eksternal masih WIP',
      ],
    },
    {
      version: '1.09.00',
      date: '2023-04-12',
      update: [
        'Menambahkan laporan penyesuaian stok',
        'Menambahkan laporan mutasi',
      ],
      improvement: [],
      dev: [
        'Ganti dns sentry menggunakan dns yang baru',
        'Hapus komponen yang sudah tidak terpakai',
      ],
    },
    {
      version: '1.08.00',
      date: '2023-04-06',
      update: [
        'Menambahkan laporan pembelian per nota',
        'Menambahkan laporan retur pembelian',
        'Menambahkan laporan pembayaran pembelian',
      ],
      improvement: [
        'Fix notifikasi error pembelian',
      ],
      dev: [
        'Fix komponen multiselect props disabled',
      ],
    },
    {
      version: '1.07.00',
      date: '2023-04-06',
      update: [
        'Menambahkan laporan penjualan per nota',
        'Menambahkan laporan retur penjualan',
      ],
      improvement: [
        'Penyesuaian stok bisa memilih tanggal',
      ],
      dev: [],
    },
    {
      version: '1.06.00',
      date: '2023-04-03',
      update: [
        'Menambahkan laporan pembelian per item',
        'Menambahkan laporan pembayaran pembelian',
      ],
      improvement: [
        'Menambahkan fitur tambah customer pada riwayat penjualan',
      ],
      dev: [],
    },
    {
      version: '1.05.00',
      date: '2023-03-31',
      update: [
        'Menambahkan export laporan penjualan per sales',
        'Menambahkan export laporan data penjualan',
        'Menambahkan export laporan kartu stok',
      ],
      improvement: [
        'Menambahkan paginasi pada laporan nilai inventaris dan laporan stok barang',
        'Menambahkan zoom tabel pada laporan stok barang',
        'Laporan stok barang bisa dihitung ulang',
      ],
      dev: [],
    },
    {
      version: '1.04.00',
      date: '2023-03-24',
      update: [
        'Menambahkan cetak dan export pembelian per supplier',
      ],
      improvement: [
        'Data log diurutkan berdasarkan tanggal paling terbaru',
        'Menambahkan nominal dengan mata uang asing pada pembayaran pembelian',
      ],
      dev: [],
    },
    {
      version: '1.03.01',
      date: '2023-03-21',
      update: [],
      improvement: [
        'Input pembelian bisa desimal pada diskon dan harga produk',
        'Perbaikan perhitungan laporan penjualan per item',
        'Perbaikan tampilan laporan data penjualan',
        'Perbaikan tampilan laporan data pembelian',
        'Menambahkan export laporan penjualan per kategori',
      ],
      dev: [],
    },
    {
      version: '1.03.00',
      date: '2023-03-17',
      update: [
        'Menambahkan log penjualan dengan stok minus',
      ],
      improvement: [],
      dev: [
        'Penjualan stok minus pada dashboard dibatasi bulan ini saja',
        'Menambahkan kolom grup pada pencarian produk',
        'Pembelian bisa input angka desimal pada harga dan diskon',
        'Produk memiliki attribut bisa dijual atau tidak',
      ],
    },
    {
      version: '1.02.01',
      date: '2023-03-16',
      update: [],
      improvement: [
        'Menambahkan export laporan penjualan per item',
        'Menambahkan export laporan penjualan per customer',
      ],
      dev: [
        'Update npm package',
      ],
    },
    {
      version: '1.02.00',
      date: '2023-03-15',
      update: [
        'Retur pembelian lama',
      ],
      improvement: [
        'Menambahkan export laporan penjualan global',
      ],
      dev: [
        'Fix form pembelian yang mengisi kode produk pada kolom nama',
      ],
    },
    {
      version: '1.01.00',
      date: '2023-03-14',
      update: [
        'Menambahkan cetak barcode sales',
      ],
      improvement: [
        'Produk bisa dihapus selama tidak memiliki riwayat log stok',
        'Form penyesuaian stok memiliki kolom sub total',
        'Halaman daftar mutasi memiliki kolom catatan pengirim',
        'Fix error pada laporan kartu stok non detail',
      ],
      dev: [],
    },
    {
      version: '1.00.07',
      date: '2023-03-07',
      update: [],
      improvement: [
        'Menambahkan tombol export stok produk',
        'Menambahkan tombol simpan produk pada detil produk pembelian',
      ],
      dev: [
        'Timeout axios diperpanjang jadi 30 menit',
        'menambahkan permission melihat penjualan stok minus',
      ],
    },
    {
      version: '1.00.06',
      date: '2023-03-06',
      update: [],
      improvement: [
        'Free item tidak terhitung dalam jumlah item yang dibeli pada struk',
        'Fix perhitungan stok pada saat cari produk and tidak ikut sertakan stok kurang dari 1',
        'Fix total laporan penjualan global',
      ],
      dev: [
        'Fix menu dropdown yang blur nya ter-trigger duluan daripada click menunya',
      ],
    },
    {
      version: '1.00.05',
      date: '2023-03-04',
      update: [
        'Menambahkan menu pengaturan toko',
      ],
      improvement: [
        'Menambahkan header dan footer pada nota penjualan',
      ],
      dev: [
        'Update pembayaran penjualan bisa 3 hari ke belakang',
      ],
    },
    {
      version: '1.00.04',
      date: '2023-03-03',
      update: [],
      improvement: [
        'Membuat tampilan lebih nyaman untuk mobile browser',
      ],
      dev: [],
    },
    {
      version: '1.00.03',
      date: '2023-03-03',
      update: [],
      improvement: [
        'Waktu pengiriman dan penerimaan pada mutasi menampilkan jam dan menit',
        'Laporan penjualan per customer menampilkan catatan',
        'Fix ukuran barcode',
      ],
      dev: [],
    },
    {
      version: '1.00.02',
      date: '2023-03-02',
      update: [],
      improvement: [
        'Pembenahan halaman yang tidak bisa diklik setelah halaman 2',
        'Pembenahan kalkulasi pada struk dengan biaya tambahan',
      ],
      dev: [
        'Set lower case pada kode produk saat scan konfirmasi mutasi',
      ],
    },
    {
      version: '1.00.01',
      date: '2023-03-02',
      update: [],
      improvement: [
        'Menambahkan catatan pada struk penjualan',
        'Menambahkan kolom nama pada pencarian produk',
        'Menambahkan tipe mutasi pada struk mutasi',
        'Daftar barang pada label pengiriman disembunyikan',
      ],
      dev: [],
    },
    {
      version: '1.00.00',
      date: '2023-03-01',
      update: [],
      improvement: [
        'Menambahkan halaman bantuan untuk google authenticator',
        'Release The APP',
      ],
      dev: [
        'Ubah table dengan paginasi dari menggunakan div menjadi menggunakan table',
        'Menambahkan permission baru dan limitasi beberapa fitur',
        'Update simulasi menu dengan permission yang baru',
        'Menambahkan style untuk table dengan paginasi dan modal',
      ],
    },
    {
      version: '0.35.01',
      date: '2023-02-25',
      update: [],
      improvement: [
        'Ubah design label pengiriman penjualan',
        'Menambahkan katalog bantuan untuk Google Authenticathor',
      ],
      dev: [
        'Fix service worker untuk otomatis update',
      ],
    },
    {
      version: '0.35.00',
      date: '2023-02-23',
      update: [
        'Daftar produk yang belum memiliki harga jual pada dashboard',
        'Daftar penjuialan dengan stok minus pada dashboard',
        'Log harga jual produk',
        'Log rumus harga jual',
        'Log nilai tukar mata uang',
      ],
      improvement: [
        'Fix pengaturan layout cetak laporan',
      ],
      dev: [
        'Fix url notifikasi',
        'Menambahkan error message pada retur penjualan yang total qty sama dengan 0',
        'Fix style untuk produk tanpa harga jual',
      ],
    },
    {
      version: '0.34.04',
      date: '2023-02-20',
      update: [],
      improvement: [
        'Minor fix',
        'Update harga dari pengaturan harga jual menggunakan nilai tukar',
      ],
      dev: [
        'Fix bug saat print label penjualan',
        'Fix detail penjualan tanpa detil penjualan (hanya extra item saja)',
        'Fix detail pembayaran penjualan tanpa detil penjualan (hanya extra item saja)',
      ],
    },
    {
      version: '0.34.03',
      date: '2023-02-20',
      update: [],
      improvement: [
        'Minor fix',
      ],
      dev: [
        'Fix detail penjualan dan memiliki cashback',
        'Fix tombol cetak kwitansi pembelian stock',
      ],
    },
    {
      version: '0.34.02',
      date: '2023-02-15',
      update: [],
      improvement: [
        'Perubahan style beberapa element',
        'Ubah layout form peran dan hak akses',
      ],
      dev: [
        'Fix border input rounded pada halaman login',
        'Ubah style css notifikasi, input box, input image',
        'Ubah script layout menggunakan setup script',
      ],
    },
    {
      version: '0.34.01',
      date: '2023-02-08',
      update: [],
      improvement: [
        'Peningkatan sistem',
      ],
      dev: [
        'Ganti halaman dashboard menggunakan full setup script',
        'Menambahkan komponen toolbar grafik statistik',
        'Ganti multiselect menggunakan custom komponen multiselect',
      ],
    },
    {
      version: '0.34.00',
      date: '2023-02-02',
      update: [
        'Laporan Master Stok Produk',
      ],
      improvement: [
        'Minor fix',
        'Halaman mutasi eksternal memiliki filter status lunas',
        'Laporan mutasi dengan filter "dibatalkan" memiliki keterangan pembatalan',
        'Halaman stok memiliki filter grup produk, minimal stok, dan maksimal stok',
        'Ubah format laporan mutasi eksternal agar sesuai dengan laporan komparasi mutasi eksternal',
      ],
      dev: [
        'Fix komponen password validation',
        'Tata ulang file dalam folder print sesuai dengan kategori',
        'Update sidebar dan menu menggunakan setup script',
      ],
    },
    {
      version: '0.33.03',
      date: '2023-01-27',
      update: [],
      improvement: [
        'Optimisasi sistem',
      ],
      dev: [
        'Buat komponen multiselect Brand, Category, PIC, Shipper, Tag',
        'Update komponen multiselect menggunakan style tailwind untuk error border',
        'Ganti komponen multiselect pada view menggunakan komponen multiselect pada folder Components',
        'Ubah formatting number untuk null dan 0',
        'Optimize icon menu dropdown biar keren :D',
        'Ubah menu dropdown menjadi komponen',
      ],
    },
    {
      version: '0.33.01',
      date: '2023-01-20',
      update: [],
      improvement: [
        'Minor fix',
      ],
      dev: [
        'Ubah parameter untuk request yang memiliki offset timezone, menjadi ISOString',
        'Pindahkan komponen AutocompleBox dan DatePicker ke dalam kategori components/Input',
      ],
    },
    {
      version: '0.33.00',
      date: '2023-01-18',
      update: [
        'Menambahkan keamanan ganda untuk akun',
        'Menambahkan fitur untuk sinkronisasi transaksi offline',
      ],
      improvement: [
        'Cetak transaksi offline tersedia pada halaman riwayat',
      ],
    },
    {
      version: '0.32.00',
      date: '2022-12-14',
      update: [
        'Interface baru untuk halaman Peran & Hak Akses',
      ],
      improvement: [
        'Update halaman bantuan untuk fitur yang sudah perbarui',
        'Fix minor bug',
      ],
    },
    {
      version: '0.31.02',
      date: '2022-12-14',
      update: [],
      improvement: [
        'Tampilan baru untuk halaman dengan filter',
      ],
    },
    {
      version: '0.31.01',
      date: '2022-12-12',
      update: [],
      improvement: [
        'Pembenahan desain halaman',
      ],
    },
    {
      version: '0.31.00',
      date: '2022-11-30',
      update: [
        'Laporan penjualan per nomer kartu',
        'Laporan penjualan per mesin EDC',
        'Laporan stok master produk & simulasi mutasi',
        'Cetak data struk penyesuaian stok',
        'Master mesin EDC',
      ],
      improvement: [
        'Penyesuaian stok bisa lebih dari 1 item',
        'Update pembayaran penjualan bisa input mesin EDC',
        'Pembaruan tampilan beberapa halaman',
      ],
    },
    {
      version: '0.30.00',
      date: '2022-11-17',
      update: [
        'Laporan pembelian per supplier',
        'Laporan penjualan per item',
        'Laporan penjualan per kategori',
        'Laporan penjualan per customer',
        'Laporan penjualan per sales',
        'Cetak data penjualan',
        'Cetak data pembelian',
        'Cetak data retur Pembelian',
        'Cetak data mutasi',
        'Cetak data supplier',
        'Cetak data customer',
      ],
      improvement: [
        'Menu laporan memiliki sub kategori',
      ],
    },
    {
      version: '0.29.02',
      date: '2022-10-19',
      update: [
        'Mutasi Eksternal',
      ],
      improvement: [
        'Optimisasi komponen tabel',
        'Optimisasi struktur file',
        'Perbaikan bug minor',
      ],
    },
    {
      version: '0.28.00',
      date: '2022-09-20',
      update: [
        'Password Cabang',
      ],
      improvement: [
        'Stok opname bisa input qty',
        'Input mutasi bisa input kode yang sama, akan menambah qty kode yang sudah diinputkan',
        'Optimisasi halaman',
      ],
    },
    {
      version: '0.27.01',
      date: '2022-08-15',
      update: [
        'Laporan Kartu Stok',
        'Laporan Nilai Inventaris',
        'Data HPP',
      ],
      improvement: [
        'Halaman transaksi untuk kasir dipisah ke domain lain',
        'Update layout Form Pembayaran Penjualan',
        'Setiap halaman memiliki judul tab yang sesuai dengan konten',
      ],
    },
    {
      version: '0.26.0',
      date: '2022-06-23',
      update: [
        'Menambahkan data brand',
        'Menambahkan pengaturan menu online shop Rain',
        'Produk online memiliki pengaturan kode warna',
      ],
      improvement: [
        'Retur pembelian bisa diambil dari lebih dari 1 nota pembelian',
        'Pengembalian dana pembelian bisa merupakan kumpulan retur pembelian',
        'Produk online memiliki 3 kategori dan terbagi menjadi 3 level',
      ],
    },
    {
      version: '0.25.0',
      date: '2022-06-01',
      update: [
        'Laporan penjualan online',
        'Cetak laporan stok opname',
      ],
      improvement: [
        'Fix kalkulasi perhitungan laporan penjualan global saat cetak.',
        'Menambahkan informasi PIC pada laporan pembelian.',
      ],
    },
    {
      version: '0.24.3',
      date: '2022-05-27',
      update: [],
      improvement: [
        'Pembelian bisa memiliki lebih dari 1 PIC.',
        'Bug fix',
      ],
    },
    {
      version: '0.24.2',
      date: '2022-05-26',
      update: [],
      improvement: [
        'Input mutasi, pembelian, dan penjualan tidak bisa menggunakan kode yang kembar',
        'Menambahkan tombol untuk mencari produk yang repeat order pada form pembelian',
        'Menambah kategori laporan pembelian, Laporan Pembelian Per Supplier',
      ],
    },
    {
      version: '0.24.0',
      date: '2022-05-24',
      update: [
        'Cetak mutasi, pembelian, dan label bisa memilih menggunakan format kertas A4 atau kertas roll',
      ],
      improvement: [
        'Menambahkan format kode produk saat input pembelian.',
        'Bila ada kesalahan dalam format, terdapat bantuan yang bisa membetulkan formatnya.',
      ],
    },
    {
      version: '0.23.0',
      date: '2022-05-11',
      update: [
        'Cetak mutasi dan pembelian dengan format kertas printer nota',
        'Pengaturan diskon produk',
      ],
      improvement: [
        'Menambahkan nilai pembayaran tunai dan kembalian pada laporan "Transaksi Hari Ini"',
        'Sidemenu bisa diminimize',
      ],
    },
    {
      version: '0.22.0',
      date: '2022-04-11',
      update: ['Stok Opname'],
      improvement: ['Bug fix transaksi'],
    },
    {
      version: '0.21.0',
      date: '2022-03-25',
      update: ['Laporan "Transaksi Hari Ini"'],
      improvement: ['OTP melalui email dihapus, diganti dengan password user'],
    },
    {
      version: '0.20.0',
      date: '2022-03-18',
      update: ['OTP email untuk transaksi dengan diskon, retur, dan biaya tambahan'],
      improvement: ['Bug fix'],
    },
    {
      version: '0.19.0',
      date: '2022-02-26',
      update: ['Alpha Release untuk trial'],
      improvement: [],
    },
  ],
})

const getters = {
  getlogs (state) {
    return state.logs
  },
}

export default {
  namespaced: true,
  state,
  getters,
}