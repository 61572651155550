export default [
  {
    path: '/product',
    children: [
      {
        path: '',
        name: 'ProductIndex',
        meta: {
          title: 'Produk',
          tag: 'product produk',
          permission: ['manage_products'],
          description: 'Halaman yang menampilkan daftar semua produk',
        },
        component: () => import(/* webpackChunkName: "poduct-index" */ '../views/Product/Index.vue'),
      },
      {
        path: ':product_id',
        name: 'ProductView',
        meta: { title: 'Produk - Tampilkan Produk' },
        component: () => import(/* webpackChunkName: "product-view" */ '../views/Product/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: ':product_id/edit',
            name: 'ProductEdit',
            meta: { title: 'Produk - Ubah Produk' },
            component: () => import(/* webpackChunkName: "product-edit" */ '../views/Product/Form.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/product-group',
    children: [
      {
        path: '',
        name: 'ProductGroupIndex',
        meta: {
          title: 'Produk Grup',
          tag: 'group product produk grup',
          permission: ['manage_products'],
          description: 'Halaman yang menampilkan daftar semua mesin edc',
        },
        component: () => import(/* webpackChunkName: "product-group-index" */ '../views/Product/Group/Index.vue'),
      },
      {
        path: ':product_group_id',
        name: 'ProductGroupView',
        meta: { title: 'Grup Produk - Tampilkan Grup' },
        component: () => import(/* webpackChunkName: "product-group-view" */ '../views/Product/Group/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'ProductGroupCreate',
            meta: {
              title: 'Grup Produk - Tambah Grup',
              tag: 'create group product tambah produk grup',
              permission: ['manage_products'],
              description: 'Halaman untuk menambah produk grup baru',
            },
            component: () => import(/* webpackChunkName: "product-group-create" */ '../views/Product/Group/Form.vue'),
          },
          {
            path: ':product_group_id/edit',
            name: 'ProductGroupEdit',
            meta: { title: 'Grup Produk - Ubah Grup' },
            component: () => import(/* webpackChunkName: "product-group-edit" */ '../views/Product/Group/Form.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/diskon-produk',
    children: [
      {
        path: '',
        name: 'ProductDiscount',
        meta: {
          title: 'Diskon Produk',
          tag: 'product discount diskon produk',
          permission: ['manage_discount_products'],
          description: 'Halaman yang menampilkan daftar semua diskon produk',
        },
        component: () => import(/* webpackChunkName: "discount-index" */ '../views/Product/Discount/Index.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'ProductDiscountCreate',
            meta: {
              title: 'Diskon Produk - Tambah Diskon Produk',
              tag: 'create product discount tambah diskon produk',
              permission: ['manage_discount_products'],
              description: 'Halaman untuk menambah diskon produk baru',
            },
            component: () => import(/* webpackChunkName: "discount-index" */ '../views/Product/Discount/Form.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/barcode',
    name: 'Barcode',
    meta: {
      title: 'Barcode Produk',
      tag: 'barcode produk',
      permission: ['generate_barcode'],
      description: 'Halaman untuk mencetak barcode produk',
    },
    component: () => import(/* webpackChunkName: "barcode-index" */ '../views/Product/Barcode/Index.vue'),
  },
]