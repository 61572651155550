export default [
  {
    path: '/mutation',
    children: [
      {
        path: '',
        name: 'MutationIndex',
        meta: {
          title: 'Mutasi',
          tag: 'mutation mutasi',
          permission: ['manage_mutations', 'view_mutation'],
          description: 'Halaman yang menampilkan semua daftar mutasi',
        },
        component: () => import(/* webpackChunkName: "mutation-index" */ '../views/Mutation/Internal/Index.vue'),
      },
      {
        path: ':mutation_id',
        name: 'MutationView',
        meta: { title: 'Mutasi - Tampilkan Mutasi' },
        component: () => import(/* webpackChunkName: "mutation-view" */ '../views/Mutation/Internal/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'MutationCreate',
            meta: {
              title: 'Mutasi - Tambah Mutasi',
              tag: 'create mutation tambah mutasi',
              permission: ['manage_mutations'],
              description: 'Halaman untuk menambah mutasi baru',
            },
            component: () => import(/* webpackChunkName: "mutation-create" */ '../views/Mutation/Internal/Form.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/mutation-external',
    children: [
      {
        path: '',
        name: 'MutationExternalIndex',
        meta: {
          title: 'Mutasi Eksternal',
          tag: 'external mutation mutasi eksternal',
          permission: ['manage_mutations', 'view_mutation'],
          description: 'Halaman yang menampilkan semua daftar mutasi eksternal',
        },
        component: () => import(/* webpackChunkName: "mutation-external-index" */ '../views/Mutation/External/Index.vue'),
      },
      {
        path: ':mutation_external_id',
        name: 'MutationExternalView',
        meta: { title: 'Mutasi Eksternal - Tampilkan Mutasi Eksternal' },
        component: () => import(/* webpackChunkName: "mutation-external-view" */ '../views/Mutation/External/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'MutationExternalCreate',
            meta: {
              title: 'Mutasi Eksternal - Tambah Mutasi Eksternal',
              tag: 'create external mutation tambah mutasi eksternal',
              permission: ['manage_mutations'],
              description: 'Halaman untuk menambah mutasi eksternal baru',
            },
            component: () => import(/* webpackChunkName: "mutation-external-create" */ '../views/Mutation/External/Form.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/mutation-external-return',
    children: [
      {
        path: '',
        name: 'MutationExternalReturnIndex',
        meta: {
          title: 'Pengembalian Mutasi Eksternal',
          tag: 'return external mutation pengembalian mutasi eksternal',
          permission: ['manage_mutations', 'view_mutation'],
          description: 'Halaman yang menampilkan semua daftar pengembalian mutasi eksternal',
        },
        component: () => import(/* webpackChunkName: "mutation-external-return-index" */ '../views/Mutation/External/Return/Index.vue'),
      },
      {
        path: ':mutation_external_return_id',
        name: 'MutationExternalReturnView',
        meta: { title: 'Pengembalian Mutasi Eksternal - Tampilkan Pengembalian Mutasi Eksternal' },
        component: () => import(/* webpackChunkName: "mutation-external-return-view" */ '../views/Mutation/External/Return/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'MutationExternalReturnCreate',
            meta: {
              title: 'Pengembalian Mutasi Eksternal - Tambah Pengembalian Mutasi Eksternal',
              tag: 'create return external mutation tambah pengembalian mutasi eksternal',
              permission: ['manage_mutations'],
              description: 'Halaman untuk menambah pengembalian mutasi eksternal baru',
            },
            component: () => import(/* webpackChunkName: "mutation-external-return-create" */ '../views/Mutation/External/Return/Form.vue'),
          },
        ],
      },
    ],
  },
]