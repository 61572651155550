export default [
  {
    path: '/banner',
    children: [
      {
        path: '',
        name: 'BannerIndex',
        meta: {
          title: 'Banner',
          tag: 'banner',
          permission: ['manage_online_shops'],
          description: 'Halaman yang menampilkan daftar semua banner e-commerce',
        },
        component: () => import(/* webpackChunkName: "banner-index" */ '../views/Banner/Index.vue'),
      },
      {
        path: ':banner_id',
        name: 'BannerView',
        meta: { title: 'Banner - Tampilkan Banner' },
        component: () => import(/* webpackChunkName: "banner-view" */ '../views/Banner/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'BannerCreate',
            meta: {
              title: 'Banner - Tambah Banner',
              tag: 'create banner tambah banner',
              permission: ['manage_online_shops'],
              description: 'Halaman untuk menambah pesanan online baru',
            },
            component: () => import(/* webpackChunkName: "banner-create" */ '../views/Banner/Form.vue'),
          },
          {
            path: ':banner_id/edit',
            name: 'BannerEdit',
            meta: { title: 'Banner - Ubah Banner' },
            component: () => import(/* webpackChunkName: "banner-edit" */ '../views/Banner/Form.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/promotion',
    children: [
      {
        path: '',
        name: 'PromotionIndex',
        meta: {
          title: 'Promosi',
          tag: 'promotion promosi',
          permission: ['manage_online_shops'],
          description: 'Halaman yang menampilkan daftar semua promosi',
        },
        component: () => import(/* webpackChunkName: "promotion-index" */ '../views/Promotion/Index.vue'),
      },
      {
        path: ':promotion_id',
        name: 'PromotionView',
        meta: { title: 'Promosi - Tampilkan Promosi' },
        component: () => import(/* webpackChunkName: "promotion-view" */ '../views/Promotion/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'PromotionCreate',
            meta: {
              title: 'Promosi - Tambah Promosi',
              tag: 'create promotion tambah promosi',
              permission: ['manage_online_shops'],
              description: 'Halaman untuk menambah promosi baru',
            },
            component: () => import(/* webpackChunkName: "promotion-create" */ '../views/Promotion/Form.vue'),
          },
          {
            path: ':promotion_id/edit',
            name: 'PromotionEdit',
            meta: { title: 'Promosi - Ubah Promosi' },
            component: () => import(/* webpackChunkName: "promotion-edit" */ '../views/Promotion/Form.vue'),
          },
        ],
      },
    ],
  },
]