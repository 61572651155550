export default [
  {
    path: '/stock',
    name: 'StockIndex',
    meta: {
      title: 'Stok Barang',
      tag: 'branch stock stok barang',
      permission: ['view_stock_branch'],
      description: 'Halaman yang menampilkan daftar semua stok pada cabang',
    },
    component: () => import(/* webpackChunkName: "stock-index" */ '../views/Product/Stock.vue'),
  },
  {
    path: '/stock/search',
    name: 'StockSearch',
    meta: {
      title: 'Cari Stok',
      tag: 'search stock cari stok',
      permission: ['search_stock'],
      description: 'Halaman untuk mencari stok barang tertentu',
    },
    component: () => import(/* webpackChunkName: "stock-index" */ '../views/Product/Search.vue'),
  },
  {
    path: '/stock-adjustment',
    children: [
      {
        path: '',
        name: 'StockAdjustmentIndex',
        meta: {
          title: 'Penyesuaian Stok',
          tag: 'stock adjustment penyesuaian stok',
          permission: ['manage_stock_adjustments'],
          description: 'Halaman yang menampilkan daftar semua penyesuaian stok',
        },
        component: () => import(/* webpackChunkName: "stock-adjustment-index" */ '../views/StockAdjustment/Index.vue'),
      },
      {
        path: ':stock_adjustment_id',
        name: 'StockAdjustmentView',
        meta: { title: 'Penyesuaian Stok - Tampilkan Penyesuaian Stok' },
        component: () => import(/* webpackChunkName: "stock-adjustment-view" */ '../views/StockAdjustment/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'StockAdjustmentCreate',
            meta: {
              title: 'Penyesuaian Stok - Tambah Penyesuaian Stok',
              tag: 'create stock adjustment tambah penyesuaian stok',
              permission: ['manage_stock_adjustments'],
              description: 'Halaman untuk menambah penyesuaian stok baru',
            },
            component: () => import(/* webpackChunkName: "stock-adjustment-create" */ '../views/StockAdjustment/Form.vue'),
          },
        ],
      },
    ],
  },
]