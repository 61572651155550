export default [
  {
    path: '/brand',
    children: [
      {
        path: '',
        name: 'BrandIndex',
        meta: {
          title: 'Brand',
          tag: 'brand',
          permission: ['manage_online_shops'],
          description: 'Halaman yang menampilkan daftar semua brand',
        },
        component: () => import(/* webpackChunkName: "brand-index" */ '../views/Brand/Index.vue'),
      },
      {
        path: ':brand_id',
        name: 'BrandView',
        meta: { title: 'Brand - Tampilkan Brand' },
        component: () => import(/* webpackChunkName: "brand-view" */ '../views/Brand/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'BrandCreate',
            meta: {
              title: 'Brand - Tambah Brand',
              tag: 'create tambah brand',
              permission: ['manage_online_shops'],
              description: 'Halaman untuk menambah brand baru',
            },
            component: () => import(/* webpackChunkName: "brand-create" */ '../views/Brand/Form.vue'),
          },
          {
            path: ':brand_id/edit',
            name: 'BrandEdit',
            meta: { title: 'Brand - Ubah Brand' },
            component: () => import(/* webpackChunkName: "brand-edit" */ '../views/Brand/Form.vue'),
          },
        ],
      },
    ],
  },
]