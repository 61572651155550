export default [
  {
    path: '/online-product',
    children: [
      {
        path: '',
        name: 'OnlineProductIndex',
        meta: { title: 'Produk Online', tag: 'online produk' },
        meta: {
          title: 'Produk Online',
          tag: 'online product produk online',
          permission: ['manage_online_products'],
          description: 'Halaman yang menampilkan daftar semua produk online',
        },
        component: () => import(/* webpackChunkName: "online-poduct-index" */ '../views/OnlineProduct/Index.vue'),
      },
      {
        path: ':product_id',
        name: 'OnlineProductView',
        meta: { title: 'Produk Online - Tampilkan Produk' },
        component: () => import(/* webpackChunkName: "online-product-view" */ '../views/OnlineProduct/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: ':product_id/edit',
            name: 'OnlineProductEdit',
            meta: { title: 'Produk Online - Ubah Produk' },
            component: () => import(/* webpackChunkName: "online-product-edit" */ '../views/OnlineProduct/Form.vue'),
          },
        ],
      },
    ],
  },
]