import { createRouter, createWebHistory } from 'vue-router'

import account from './account'
import authRoutes from './auth'
import branch from './branch'
import brand from './brand'
import cashier from './cashier'
import category from './category'
import colorCode from './colorCode'
import currency from './currency'
import customer from './customer'
import exportData from './exportData'
import log from './log'
import member from './member'
import mutation from './mutation'
import onlineOrder from './onlineOrder'
import onlineProduct from './onlineProduct'
import onlineShop from './onlineShop'
import paymentMethod from './paymentMethod'
import printRoutes from './printPage'
import product from './product'
import purchase from './purchase'
import report from './report'
import rolePermission from './rolePermission'
import sale from './sale'
import salesPromotionGirl from './salesPromotionGirl'
import setting from './setting'
import shipper from './shipper'
import stock from './stock'
import stockopname from './stockopname'
import supplier from './supplier'
import user from './user'

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard" */ '../layouts/Dashboard/Index.vue'),
    children: [
      {
        path: '/',
        name: 'Dashboard',
        meta: { title: 'Dashboard' },
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/Home.vue'),
      },
      {
        path: '/forbidden',
        name: '403',
        meta: { title: 'Forbidden' },
        component: () => import(/* webpackChunkName: "errors" */ '../views/Error/403.vue'),
      },
      {
        path: '/:pathMatch(.*)*',
        name: '404',
        meta: { title: 'Not Found' },
        component: () => import(/* webpackChunkName: "errors" */ '../views/Error/404.vue'),
      },
      {
        path: '/maintenance',
        name: '503',
        meta: { title: 'Maintenance' },
        component: () => import(/* webpackChunkName: "errors" */ '../views/Error/503.vue'),
      },
      ...account,
      ...branch,
      ...brand,
      ...cashier,
      ...category,
      ...colorCode,
      ...currency,
      ...customer,
      ...exportData,
      ...log,
      ...member,
      ...mutation,
      ...onlineOrder,
      ...onlineProduct,
      ...onlineShop,
      ...paymentMethod,
      ...product,
      ...purchase,
      ...report,
      ...rolePermission,
      ...sale,
      ...salesPromotionGirl,
      ...setting,
      ...shipper,
      ...stock,
      ...stockopname,
      ...supplier,
      ...user,
    ],
  },
  authRoutes,
  printRoutes,
  {
    path: '/help',
    name: 'Help',
    meta: { title: 'Bantuan' },
    component: () => import(/* webpackChunkName: "help" */ '../views/Help/Index.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
  // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  next()
})

export default router
