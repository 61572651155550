export default [
  {
    path: '/currency',
    children: [
      {
        path: '',
        name: 'CurrencyIndex',
        meta: {
          title: 'Mata Uang',
          tag: 'currency mata uang',
          permission: ['manage_currencies'],
          description: 'Halaman yang menampilkan daftar semua mata uang',
        },
        component: () => import(/* webpackChunkName: "currency-index" */ '../views/Currency/Index.vue'),
      },
      {
        path: ':currency_id',
        name: 'CurrencyView',
        meta: { title: 'Mata Uang - Tampilkan Mata Uang' },
        component: () => import(/* webpackChunkName: "currency-view" */ '../views/Currency/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'CurrencyCreate',
            meta: {
              title: 'Mata Uang - Tambah Mata Uang',
              tag: 'create currency tambah mata uang',
              permission: ['manage_currencies'],
              description: 'Halaman untuk menambah mata uang baru',
            },
            component: () => import(/* webpackChunkName: "currency-create" */ '../views/Currency/Form.vue'),
          },
          {
            path: ':currency_id/edit',
            name: 'CurrencyEdit',
            meta: { title: 'Mata Uang - Ubah Mata Uang' },
            component: () => import(/* webpackChunkName: "currency-edit" */ '../views/Currency/Form.vue'),
          },
        ],
      },
    ],
  },
]