<script setup>
import { ref } from 'vue'
const emit = defineEmits(['confirm', 'cancel', 'close'])
const props = defineProps({
  customTitle: {
    type: String,
    default: 'Konfirmasi',
  },
  customText: {
    type: String,
    default: 'Anda yakin ingin mengubah data ini?',
  },
  confirmButton: {
    type: String,
    default: 'Simpan',
  },
  manualClose: {
    type: Boolean,
    default: false,
  },
})

const submitting = ref(false)
const modal = ref(null)

const open = () => {
  modal.value.open()
  submitting.value = false
}
const close = () => {
  modal.value.close()
}
const confirm = () => {
  emit('confirm')
  submitting.value = true
  if (!props.manualClose) {
    close()
  }
}
const cancel = () => {
  emit('cancel')
  close()
}
const enableButton = () => {
  submitting.value = false
}
defineExpose({ open, close, enableButton })
</script>
<template>
  <modal-component ref="modal" :can-click-outside="manualClose ? !submitting : true" @closed="emit('close')">
    <template #header>
      <div class="modal-head">
        <div class="pt-4">
          <div class="text-base font-semibold capitalize">{{ customTitle }}</div>
        </div>
      </div>
    </template>
    <template #content>
      <div class="modal-body">
        <hr class="h-0 mx-8 text-center border-2 border-blue-500">
        <div class="px-8 py-4">
          <div>{{ customText }}</div>
          <slot></slot>
        </div>
        <div class="flex items-center justify-center space-x-4 text-sm">
          <g-button
            class="w-32 btn btn-primary"
            :type="'button'"
            :loading="submitting"
            @click="confirm">
            {{ confirmButton }}
          </g-button>
          <g-button
            class="w-32 btn btn-outline"
            :type="'button'"
            :disabled="submitting"
            @click="cancel">
            Batal
          </g-button>
        </div>
      </div>
    </template>
  </modal-component>
</template>