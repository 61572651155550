<script setup>
import { computed, inject } from 'vue'
const props = defineProps({
  value: {
    type: Object,
    required: true,
  },
})
const router = inject('router')
const route = inject('route')
const fmt = inject('fmt')
const prevLink = computed(() => {
  const page = currentPage.value === 2 ? undefined : (currentPage.value - 1)
  return { ...route, query: { ...route.query, page } }
})
const nextLink = computed(() => {
  return { ...route, query: { ...route.query, page: currentPage.value + 1 } }
})
const paginationText = computed(() => {
  const { from, to, total } = props.value

  return `Menampilkan ${fmt.number(from)}-${fmt.number(to)} dari ${fmt.number(total)} hasil.`
})
const currentPage = computed(() => props.value.current_page)

const resetPage = () => {
  const page = undefined
  router.push({ ...route, query: { ...route.query, page } })
}
defineExpose({ resetPage })
</script>
<template>
  <template v-if="value.last_page >= 1 && value.total > 0">
    <nav
      class="flex items-center justify-between p-4"
      role="navigation"
      aria-label="Pagination Navigation">
      <div class="flex-grow-0 text-center">
        {{ paginationText }}
      </div>
      <div class="flex items-center justify-center flex-grow-0">
        <div>
          <button
            :disabled="currentPage===1"
            class="px-2 mr-4 rounded btn-primary">
            <router-link
              v-if="currentPage > 1"
              :to="prevLink">
              Kembali
            </router-link>
            <span
              v-else>
              Kembali
            </span>
          </button>
        </div>
        <div class="justify-center">
          {{ fmt.number(currentPage) }}
        </div>
        <div class="ml-2 mr-4">
          /
        </div>
        <div class="justify-center">
          {{ fmt.number(value.last_page) }}
        </div>
        <div>
          <button
            :disabled="value.last_page===currentPage"
            class="px-2 ml-4 rounded btn-primary">
            <router-link
              v-if="value.last_page > currentPage"
              :to="nextLink">
              Lanjut
            </router-link>
            <span
              v-else>
              Lanjut
            </span>
          </button>
        </div>
      </div>
    </nav>
  </template>
</template>