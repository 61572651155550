import axios from 'axios'
import createStore from '../store/index'

export default class Api {

  constructor (router) {
    axios.defaults.withCredentials = true
    this.router = router
    this.store = createStore
    this.config = {
      baseURL: import.meta.env.VITE_URL_API,
      timeout: 30 * 60 * 1000, // 30 minute
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Accept-Language': 'id',
      },
    }
  }

  REQUEST (conf) {
    return new Promise((resolve, reject) => {
      axios
        .request(conf)
        .then(response => {
          if (response.status === 200 && response.headers.has('x-sw-cache')) {
            this.store.commit('setNotification', {
              open: true,
              message: this.store.state.templateText.show_cached,
              type: 'info',
            })
          }
          resolve(response)
        })
        .catch(error => {
          this.handlesError(error)
          reject(error)
        })
    })
  }
  GET (url, params) {
    return this.REQUEST({ ...this.config, method: 'get', url, params })
  }
  POST (url, data) {
    return this.REQUEST({ ...this.config, method: 'post', url, data })
  }
  POSTFORMDATA (url, data) {
    const config = {
      baseURL: import.meta.env.VITE_URL_API,
      timeout: 30 * 60 * 1000, // 30 minute
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
        'Accept-Language': 'id',
      },
    }
    return this.REQUEST({ ...config, method: 'post', url, data })
  }
  DELETE (url, data) {
    return this.REQUEST({ ...this.config, method: 'delete', url, data })
  }
  PUT (url, data) {
    return this.REQUEST({ ...this.config, method: 'put', url, data })
  }
  PATCH (url, data) {
    return this.REQUEST({ ...this.config, method: 'patch', url, data })
  }

  /* https://github.com/axios/axios#handling-errors */
  handlesError (error) {
    if (error.response) {
      /**
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      let message = error.response ? error.response.data.message : this.store.state.templateText.err_connection
      if (error.response.status === 503) {
        this.router.replace({ name: '503' })
      } else if (error.response.status === 401 && location.pathname !== 'login') {
        // use random number to prevent redirect to same url
        // this.router.replace({ name: 'Login' })
        const path = location.pathname.substring(1) + location.search
        const query = path ? { r: path } : {}
        this.router.replace({ name: 'Login', query })
        // const id = Math.floor(Math.random() * 1000000 + 1)
        // this.router.replace({ name: 'Login', query: { id } })
      } else if (error.response.status === 403) {
        this.router.replace({ name: '403' })
      } else if (error.response.status === 404) {
        this.router.replace({
          name: '404',
          params: { pathMatch: window.location.pathname.substring(1).split('/') },
          query: window.location.query,
          hash: window.location.hash,
        })
      } else if (error.response.status === 422 || error.response.status === 500) {
        this.store.commit('setNotification', {
          open: true,
          message: error.response.status === 500 ? this.store.state.templateText.server_error : message,
          type: 'danger',
        })
      } else {
        console.log(error.response)
        this.store.commit('setNotification', {
          open: true,
          message: message,
          type: 'warning',
          url: null,
        })
      }
    } else if (error.request) {
      /**
       * The request was made but no response was received.
       * `error.request` is an instance of XMLHttpRequest
       * in the browser and an instance of
       * http.ClientRequest in node.js
       */
    } else {
      /* Something happened in setting up the request that triggered an Error */
    }
  }
}
