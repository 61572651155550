export default [
  {
    path: '/sales-promotion-girl',
    children: [
      {
        path: '',
        name: 'SalesPromotionGirlIndex',
        meta: {
          title: 'Sales',
          tag: 'sales pic',
          permission: ['manage_admins'],
          description: 'Halaman yang menampilkan daftar semua sales',
        },
        component: () => import(/* webpackChunkName: "sales-promotion-girl-index" */ '../views/SalesPromotionGirl/Index.vue'),
      },
      {
        path: ':sales_promotion_girl_id',
        name: 'SalesPromotionGirlView',
        meta: { title: 'Sales - Tampilkan Sales' },
        component: () => import(/* webpackChunkName: "sales-promotion-girl-view" */ '../views/SalesPromotionGirl/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'SalesPromotionGirlCreate',
            meta: {
              title: 'Sales - Tambah Sales',
              tag: 'create sales tambah pic',
              permission: ['manage_admins'],
              description: 'Halaman untuk menambah sales baru',
            },
            component: () => import(/* webpackChunkName: "sales-promotion-girl-create" */ '../views/SalesPromotionGirl/Form.vue'),
          },
          {
            path: ':sales_promotion_girl_id/edit',
            name: 'SalesPromotionGirlEdit',
            meta: { title: 'Sales - Ubah Sales' },
            component: () => import(/* webpackChunkName: "sales-promotion-girl-edit" */ '../views/SalesPromotionGirl/Form.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/point-setting',
    children: [
      {
        path: '',
        name: 'PointSettingIndex',
        meta: {
          title: 'Pengaturan Poin Sales',
          tag: 'point sales setting pengaturan poin pic',
          permission: ['manage_points'],
          description: 'Halaman yang menampilkan daftar semua pengaturan poin sales',
        },
        component: () => import(/* webpackChunkName: "sales-promotion-girl-point-index" */ '../views/SalesPromotionGirlPoint/Index.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'PointSettingCreate',
            meta: {
              title: 'Pengaturan Poin Sales - Tambah Pengaturan',
              tag: 'create point sales setting tambah pengaturan poin pic',
              permission: ['manage_points'],
              description: 'Halaman untuk menambah pengaturan poin sales baru',
            },
            component: () => import(/* webpackChunkName: "sales-promotion-girl-point-create" */ '../views/SalesPromotionGirlPoint/Form.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/point-adjustment',
    children: [
      {
        path: '',
        name: 'PointAdjustmentIndex',
        meta: {
          title: 'Penyesuaian Poin Sales',
          tag: 'sales point adjustment penyesuaian poin pic',
          permission: ['manage_points'],
          description: 'Halaman yang menampilkan daftar semua penyesuaian poin sales',
        },
        component: () => import(/* webpackChunkName: "point-adjustment-index" */ '../views/PointAdjustment/Index.vue'),
      },
      {
        path: ':point_adjustment_id',
        name: 'PointAdjustmentView',
        meta: { title: 'Penyesuaian Poin Sales - Tampilkan Penyesuaian' },
        component: () => import(/* webpackChunkName: "point-adjustment-view" */ '../views/PointAdjustment/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'PointAdjustmentCreate',
            meta: {
              title: 'Penyesuaian Poin Sales - Tambah Penyesuaian',
              tag: 'create sales point adjustment tambah penyesuaian poin pic',
              permission: ['manage_points'],
              description: 'Halaman untuk menambah penyesuaian poin sales baru',
            },
            component: () => import(/* webpackChunkName: "point-adjustment-create" */ '../views/PointAdjustment/Form.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/point-history',
    name: 'PointHistory',
    meta: {
      title: 'Riwayat Poin Sales',
      tag: 'sales point history riwayat poin pic',
      permission: ['manage_points'],
      description: 'Halaman yang menampilkan daftar semua riwayat poin pic',
    },
    component: () => import(/* webpackChunkName: "sales-promotion-girl-point-history" */ '../views/SalesPromotionGirlPoint/PointHistory.vue'),
  },
]