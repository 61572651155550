export default [
  {
    path: '/purchase',
    children: [
      {
        path: '',
        name: 'PurchaseIndex',
        meta: {
          title: 'Pembelian Stok',
          tag: 'stock purchase pembelian stok',
          permission: ['manage_purchases', 'view_purchase'],
          description: 'Halaman yang menampilkan daftar semua pembelian',
        },
        component: () => import(/* webpackChunkName: "purchase-index" */ '../views/Purchase/Index.vue'),
      },
      {
        path: ':purchase_id',
        name: 'PurchaseView',
        meta: { title: 'Pembelian Stok - Tampilkan Pembelian' },
        component: () => import(/* webpackChunkName: "purchase-view" */ '../views/Purchase/View.vue'),
      },
      {
        path: ':purchase_id/payment/:purchase_payment_id',
        name: 'PurchasePaymentView',
        meta: { title: 'Pembayaran Pembelian - Tampilkan Pembayaran' },
        component: () => import(/* webpackChunkName: "purchase-payment-view" */ '../views/Purchase/Payment/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'PurchaseCreate',
            meta: {
              title: 'Pembelian Stok - Tambah Pembelian',
              tag: 'create purchase tambah pembelian',
              permission: ['manage_purchases', 'view_purchase'],
              description: 'Halaman untuk menambah pembelian baru',
            },
            component: () => import(/* webpackChunkName: "purchase-create" */ '../views/Purchase/Form.vue'),
          },
          {
            path: ':purchase_id/edit',
            name: 'PurchaseEdit',
            meta: { title: 'Pembelian Stok - Ubah Pembelian' },
            component: () => import(/* webpackChunkName: "purchase-edit" */ '../views/Purchase/Form.vue'),
          },
          {
            path: ':purchase_id/payment/create',
            name: 'PurchasePaymentCreate',
            meta: { title: 'Pembayaran Pembelian - Tambah Pembayaran' },
            component: () => import(/* webpackChunkName: "purchase-payment-create" */ '../views/Purchase/Payment/Form.vue'),
          },
          {
            path: ':purchase_id/payment/:purchase_payment_id/edit',
            name: 'PurchasePaymentEdit',
            meta: { title: 'Pembayaran Pembelian - Ubah Pembayaran' },
            component: () => import(/* webpackChunkName: "purchase-payment-edit" */ '../views/Purchase/Payment/Form.vue'),
          },
          {
            path: ':purchase_id/shipping/create',
            name: 'PurchaseCreateShipping',
            meta: { title: 'Pengiriman Pembelian - Tambah Pengiriman' },
            component: () => import(/* webpackChunkName: "purchase-shipping" */ '../views/Purchase/ShippingForm.vue'),
          },
          {
            path: ':purchase_id/shipping/:purchase_shipping_id/edit',
            name: 'PurchaseEditShipping',
            meta: { title: 'Pengiriman Pembelian - Ubah Pengiriman' },
            component: () => import(/* webpackChunkName: "purchase-edit-shipping" */ '../views/Purchase/ShippingForm.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/purchase-retur',
    children: [
      {
        path: '',
        name: 'PurchaseReturIndex',
        meta: {
          title: 'Pengembalian Pembelian',
          tag: 'purchase retur pengembalian pembelian',
          permission: ['manage_purchase_returs', 'view_purchase_retur'],
          description: 'Halaman yang menampilkan daftar semua pengembalian pembelian',
        },
        component: () => import(/* webpackChunkName: "purchase-retur-index" */ '../views/Purchase/Retur/Index.vue'),
      },
      {
        path: ':purchase_retur_id',
        name: 'PurchaseReturView',
        meta: { title: 'Pengembalian Pembelian - Tampilkan Pengembalian' },
        component: () => import(/* webpackChunkName: "purchase-retur-view" */ '../views/Purchase/Retur/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'PurchaseReturCreate',
            meta: {
              title: 'Pengembalian Pembelian - Tambah Pengembalian',
              tag: 'create purchase retur tambah pengembalian pembelian',
              permission: ['manage_purchase_returs', 'view_purchase_retur'],
              description: 'Halaman untuk menambah pengembalian pembelian',
            },
            component: () => import(/* webpackChunkName: "purchase-retur-create" */ '../views/Purchase/Retur/Form.vue'),
          },
          {
            path: ':purchase_retur_id/solution/:purchase_retur_detail_id',
            name: 'PurchaseReturSolution',
            meta: { title: 'Pengembalian Pembelian - Tambah Solusi' },
            component: () => import(/* webpackChunkName: "purchase-retur-solution" */ '../views/Purchase/Retur/SolutionForm.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/purchase-refund',
    children: [
      {
        path: '',
        name: 'PurchaseRefundIndex',
        meta: {
          title: 'Pengembalian Dana Pembelian',
          tag: 'purchase refund pengembalian dana pembelian',
          permission: ['manage_purchase_refundss', 'view_purchase_refund'],
          description: 'Halaman yang menampilkan daftar semua pengembalian dana pembelian',
        },
        component: () => import(/* webpackChunkName: "purchase-refund-index" */ '../views/Purchase/Refund/Index.vue'),
      },
      {
        path: ':purchase_refund_id',
        name: 'PurchaseRefundView',
        meta: { title: 'Pengembalian Dana Pembelian - Tampilkan Pengembalian Dana' },
        component: () => import(/* webpackChunkName: "purchase-refund-view" */ '../views/Purchase/Refund/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'PurchaseRefundCreate',
            meta: {
              title: 'Pengembalian Dana Pembelian - Tambah Pengembalian Dana',
              tag: 'create purchase refund tambah pengembalian dana pembelian',
              permission: ['manage_purchase_refundss', 'view_purchase_refund'],
              description: 'Halaman untuk menambah pengembalian dana pembelian',
            },
            component: () => import(/* webpackChunkName: "purchase-refund-create" */ '../views/Purchase/Refund/Form.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/purchase-shipping',
    children: [
      {
        path: '',
        name: 'PurchaseShippingIndex',
        meta: {
          title: 'Pengiriman Pembelian',
          tag: 'purchase shipping pengiriman pembelian',
          permission: ['manage_purchase_shippings', 'view_purchase_shipping'],
          description: 'Halaman yang menampilkan daftar semua pengiriman pembelian',
        },
        component: () => import(/* webpackChunkName: "purchase-shipping-index" */ '../views/Purchase/Shipping/Index.vue'),
      },
      // {
      //   path: 'create',
      //   name: 'PurchaseShippingCreate',
      // meta: { title: 'Pengiriman Pembelian - Tambah Pengiriman' },
      //   component: () => import(/* webpackChunkName: "purchase-shipping-create" */ '../views/PurchaseShipping/Form.vue'),
      // },
      // {
      //   path: ':purchase_shipping_id',
      //   name: 'PurchaseShippingView',
      // meta: { title: 'Pengiriman Pembelian - Tampilkan Pengiriman' },
      //   component: () => import(/* webpackChunkName: "purchase-shipping-view" */ '../views/PurchaseShipping/View.vue'),
      // },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: ':purchase_shipping_id/edit',
            name: 'PurchaseShippingEdit',
            meta: { title: 'Pengiriman Pembelian - Ubah Pengiriman' },
            component: () => import(/* webpackChunkName: "purchase-shipping-edit" */ '../views/Purchase/Shipping/Form.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/purchase-payment',
    children: [
      {
        path: '',
        name: 'PurchasePaymentIndex',
        meta: {
          title: 'Pembayaran Pembelian',
          tag: 'purchase payment pembayaran pembelian',
          permission: ['manage_purchase_payments', 'view_purchase_payment'],
          description: 'Halaman yang menampilkan daftar semua pembayaran pembelian',
        },
        component: () => import(/* webpackChunkName: "purchase-payment-index" */ '../views/Purchase/Payment/Index.vue'),
      },
      {
        path: ':purchase_payment_id',
        name: 'PurchasePaymentView',
        meta: { title: 'Pembayaran Pembelian - Tampilkan Pembayaran' },
        component: () => import(/* webpackChunkName: "purchase-payment-view" */ '../views/Purchase/Payment/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'PurchasePaymentCreate',
            meta: {
              title: 'Pembayaran Pembelian - Tambah Pembayaran',
              tag: 'create purchase payment tambah pembayaran pembelian',
              permission: ['manage_purchase_payments', 'view_purchase_payment'],
              description: 'Halaman untuk menambah pembayaran pembelian',
            },
            component: () => import(/* webpackChunkName: "purchase-payment-create" */ '../views/Purchase/Payment/Form.vue'),
          },
          {
            path: ':purchase_id/edit',
            name: 'PurchasePaymentEdit',
            meta: { title: 'Pembayaran Pembelian - Ubah Pembayaran' },
            component: () => import(/* webpackChunkName: "purchase-payment-edit" */ '../views/Purchase/Payment/Form.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/old-purchase-retur',
    children: [
      {
        path: '',
        name: 'OldPurchaseReturIndex',
        meta: {
          title: 'Pengembalian Pembelian Lama',
          tag: 'old purchase retur pengembalian pembelian lama',
          permission: ['manage_purchase_returs', 'view_purchase_retur'],
          description: 'Halaman yang menampilkan daftar semua pengembalian pembelian lama',
        },
        component: () => import(/* webpackChunkName: "purchase-retur-index" */ '../views/Purchase/ReturByProduct/Index.vue'),
      },
      {
        path: ':purchase_retur_id',
        name: 'OldPurchaseReturView',
        meta: { title: 'Pengembalian Pembelian Lama - Tampilkan Pengembalian' },
        component: () => import(/* webpackChunkName: "purchase-retur-view" */ '../views/Purchase/ReturByProduct/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'OldPurchaseReturCreate',
            meta: {
              title: 'Pengembalian Pembelian Lama - Tambah Pengembalian',
              tag: 'create old purchase retur tambah pengembalian pembelian lama',
              permission: ['manage_purchase_returs', 'view_purchase_retur'],
              description: 'Halaman untuk menambah pengembalian pembelian lama',
            },
            component: () => import(/* webpackChunkName: "purchase-retur-create" */ '../views/Purchase/ReturByProduct/Form.vue'),
          },
        ],
      },
    ],
  },
]