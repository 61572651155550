export default [
  {
    path: '/category',
    children: [
      {
        path: '',
        name: 'CategoryIndex',
        meta: {
          title: 'Kategori',
          tag: 'category kategori',
          permission: ['manage_online_products'],
          description: 'Halaman yang menampilkan daftar semua kategori',
        },
        component: () => import(/* webpackChunkName: "category-index" */ '../views/Category/Index.vue'),
      },
      {
        path: ':category_id',
        name: 'CategoryView',
        meta: { title: 'Kategori - Tampilkan Kategori' },
        component: () => import(/* webpackChunkName: "category-view" */ '../views/Category/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'CategoryCreate',
            meta: {
              title: 'Kategori - Tambah Kategori',
              tag: 'create category tambah kategori',
              permission: ['manage_online_products'],
              description: 'Halaman untuk menambah kategori baru',
            },
            component: () => import(/* webpackChunkName: "category-create" */ '../views/Category/Form.vue'),
          },
          {
            path: ':category_id/edit',
            name: 'CategoryEdit',
            meta: { title: 'Kategori - Ubah Kategori' },
            component: () => import(/* webpackChunkName: "category-edit" */ '../views/Category/Form.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/category-menu',
    name: 'CategoryMenu',
    meta: {
      title: 'Menu Kategori',
      tag: 'menu category kategori',
      permission: ['manage_online_shops'],
      description: 'Halaman untuk mengatur menu kategori pada toko online',
    },
    component: () => import(/* webpackChunkName: "category-index" */ '../views/Category/Menu/Index.vue'),
  },
]