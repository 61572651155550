export default [
  {
    path: '/supplier',
    children: [
      {
        path: '',
        name: 'SupplierIndex',
        meta: {
          title: 'Supplier',
          tag: 'supplier',
          permission: ['manage_suppliers'],
          description: 'Halaman yang menampilkan daftar semua supplier',
        },
        component: () => import(/* webpackChunkName: "supplier-index" */ '../views/Supplier/Index.vue'),
      },
      {
        path: ':supplier_id',
        name: 'SupplierView',
        meta: { title: 'Supplier - Tampilkan Supplier' },
        component: () => import(/* webpackChunkName: "supplier-view" */ '../views/Supplier/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'SupplierCreate',
            meta: {
              title: 'Supplier - Tambah Supplier',
              tag: 'create supplier',
              permission: ['manage_suppliers'],
              description: 'Halaman untuk menambah supplier baru',
            },
            component: () => import(/* webpackChunkName: "supplier-create" */ '../views/Supplier/Form.vue'),
          },
          {
            path: ':supplier_id/edit',
            name: 'SupplierEdit',
            meta: { title: 'Supplier - Ubah Supplier' },
            component: () => import(/* webpackChunkName: "supplier-edit" */ '../views/Supplier/Form.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/product-multiplier',
    children: [
      {
        path: '',
        name: 'SupplierProductMultiplierIndex',
        meta: {
          title: 'Pengali Harga Jual',
          tag: 'supplier multiplier pengali harga jual',
          permission: ['manage_supplier_multipliers'],
          description: 'Halaman yang menampilkan daftar semua pengali harga jual',
        },
        component: () => import(/* webpackChunkName: "supplier-product-multiplier-index" */ '../views/SupplierProductMultiplier/Index.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'SupplierProductMultiplierCreate',
            meta: {
              title: 'Pengali Harga Jual - Tambah Pengali',
              tag: 'create supplier multiplier tambah pengali harga jual',
              permission: ['manage_supplier_multipliers'],
              description: 'Halaman untuk menambah pengali harga jual baru',
            },
            component: () => import(/* webpackChunkName: "supplier-product-multiplier-create" */ '../views/SupplierProductMultiplier/Form.vue'),
          },
        ],
      },
    ],
  },
]