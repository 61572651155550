import router from '../../router'

const state = () => ({
  routeList: [],
})

const getters = {
  getRouteList: (state, getters, rootState) => (filter) => {
    if (filter.length < 3) return []
    return state.routeList.filter(item =>
      (item.meta?.tag?.includes(filter.toLowerCase()) ?? false) &&
      (item.meta?.permission?.length > 0 ? compareArrays(item.meta.permission, rootState.permissions.permissions) : true),
    )
  },
}

const actions = {
  initRouteList ({ commit }) {
    commit('setRouteList')
  },
}

const mutations = {
  setRouteList (state) {
    state.routeList = flattenRoute(router.options.routes)
  },
}

function flattenRoute (a, r) {
  if (!r) {
    r = []
  }
  for (var i = 0; i < a.length; i++) {
    if (Array.isArray(a[i].children)) {
      flattenRoute(a[i].children, r)
    } else {
      r.push(a[i])
    }
  }
  return r
}

function compareArrays (arr1, arr2) {
  return arr1.some(r=> arr2.includes(r))
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}