export default [
  {
    path: '/edc-machine',
    children: [
      {
        path: '',
        name: 'EdcMachineIndex',
        meta: {
          title: 'Mesin Edc',
          tag: 'edc machine mesin edc',
          permission: ['manage_payments'],
          description: 'Halaman yang menampilkan daftar semua mesin edc',
        },
        component: () => import(/* webpackChunkName: "edc-machine-index" */ '../views/EdcMachine/Index.vue'),
      },
      {
        path: ':edc_machine_id',
        name: 'EdcMachineView',
        meta: { title: 'Mesin Edc - Tampilkan Mesin Edc' },
        component: () => import(/* webpackChunkName: "edc-machine-view" */ '../views/EdcMachine/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'EdcMachineCreate',
            meta: {
              title: 'Mesin Edc - Tambah Mesin Edc',
              tag: 'create edc machine tambah mesin edc',
              permission: ['manage_payments'],
              description: 'Halaman untuk menambah mesin edc baru',
            },
            component: () => import(/* webpackChunkName: "edc-machine-create" */ '../views/EdcMachine/Form.vue'),
          },
          {
            path: ':edc_machine_id/edit',
            name: 'EdcMachineEdit',
            meta: { title: 'Mesin Edc - Ubah Mesin Edc' },
            component: () => import(/* webpackChunkName: "edc-machine-edit" */ '../views/EdcMachine/Form.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/payment-method',
    children: [
      {
        path: '',
        name: 'PaymentMethodIndex',
        meta: {
          title: 'Metode Pembayaran',
          tag: 'payment method metode pembayaran',
          permission: ['manage_payments'],
          description: 'Halaman yang menampilkan daftar semua metode pembayaran',
        },
        component: () => import(/* webpackChunkName: "payment-method-index" */ '../views/PaymentMethod/Index.vue'),
      },
      {
        path: ':payment_method_id',
        name: 'PaymentMethodView',
        meta: { title: 'Metode Pembayaran - Tampilkan Metode Pembayaran' },
        component: () => import(/* webpackChunkName: "payment-method-view" */ '../views/PaymentMethod/View.vue'),
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "form-layout" */ '../layouts/Dashboard/Form.vue'),
        children: [
          {
            path: 'create',
            name: 'PaymentMethodCreate',
            meta: {
              title: 'Metode Pembayaran - Tambah Metode Pembayaran',
              tag: 'create payment method tambah metode pembayaran',
              permission: ['manage_payments'],
              description: 'Halaman untuk menambah metode pembayaran baru',
            },
            component: () => import(/* webpackChunkName: "payment-method-create" */ '../views/PaymentMethod/Form.vue'),
          },
          {
            path: ':payment_method_id/edit',
            name: 'PaymentMethodEdit',
            meta: { title: 'Metode Pembayaran - Ubah Metode Pembayaran' },
            component: () => import(/* webpackChunkName: "payment-method-edit" */ '../views/PaymentMethod/Form.vue'),
          },
        ],
      },
    ],
  },
]