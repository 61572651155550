export default [
  {
    path: '/member',
    children: [
      {
        path: '',
        name: 'MemberIndex',
        meta: {
          title: 'Member', tag: 'member',
          tag: 'member',
          permission: ['manage_members', 'view_member'],
          description: 'Halaman yang menampilkan daftar semua member',
        },
        component: () => import(/* webpackChunkName: "member-index" */ '../views/Member/Index.vue'),
      },
      {
        path: ':member_id',
        name: 'MemberView',
        meta: { title: 'Member - Tampilkan Member' },
        component: () => import(/* webpackChunkName: "member-index" */ '../views/Member/View.vue'),
      },
    ],
  },
]