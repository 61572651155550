export default {
  path: '/',
  component: () => import(/* webpackChunkName: "auth" */ '../layouts/Auth/Index.vue'),
  children: [
    {
      path: '/login',
      name: 'Login',
      meta: { title: 'Rain Admin - Login' },
      component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/Login.vue'),
    },
    {
      path: '/password/forgot',
      name: 'ForgotPassword',
      meta: { title: 'Rain Admin - Lupa Password' },
      component: () => import(/* webpackChunkName: "auth-forgot" */ '../views/Auth/PasswordRequest.vue'),
    },
    {
      path: '/reset-password/:token',
      name: 'ResetPassword',
      meta: { title: 'Rain Admin - Reset Password' },
      component: () => import(/* webpackChunkName: "auth-reset" */ '../views/Auth/PasswordReset.vue'),
    },
  ],
}